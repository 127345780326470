//******************************************************************************
// SLIDER
// Carousel styles
//******************************************************************************

.slider-block {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	color: #333;

	.slider__item {
	  	text-align: center;
	 	color: #000000;
	 	background: white;
	}
}

.stories-slider {
	&__slide {
		display: flex !important;

		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
		}
	}

	.slider-arrow {
		background-color: currentColor;
	}	
}

.activities-slider {
	.slick-track {
		min-width: 100% !important;
	}

	&__slide {
		padding: 0 15px;
	}
}

.slider__slide {
	.btn--link {
		color: $font-clr;
	}
}

.slider-arrow,
.slick-arrow {
	height: 38px;
	width: 38px;
	display: inline-block;
	cursor: pointer;
	border-radius: 50%;
	border: none;
	background-color: $font-clr;
	background-color: var(--scheme-clr);
	background-repeat: no-repeat;
	background-position: center center;
	font-size: 0;
	vertical-align: middle;
	color: inherit;

	&#left-arrow,
	&.slick-prev,
	&.left-arrow {
		background-image: url('../assets/images/arrow-white-left.png');
	}
	
	&#right-arrow,
	&.slick-next,
	&.right-arrow {
		background-image: url('../assets/images/arrow-white-right.png');
		margin-left: .5rem;
	}

	&:hover {
		opacity: .5;
	}
}

$imageSliderImageHeight: 640px;

.image-slider {
	position: relative;
	padding-right: 1.8rem;
	overflow: hidden;

	figure {
		margin-bottom: 0;
		img {
			width: 100%;
			max-width: 100%;
			height: $imageSliderImageHeight;
			object-fit: cover;
		}

		.lightbox {
			position: relative;
			display: block;

			&:after {
				content: "";
				background-image: url('../assets/images/plus-white.png');
				background-repeat: no-repeat;
				background-position: center center;
				background-color: #222;
				background-color: var(--scheme-clr);
				height: 38px;
				width: 38px;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				&:after {
					opacity: .5;
				}
			}
		}
	}

	.slick-list {
		overflow: visible;
	}

	.slick-arrow {
		position: absolute;
		top: 30rem;
		right: 0;
		z-index: 10;
		background-color: #222;
		background-color: var(--scheme-clr);

		&.slick-prev {
			right: 50px;
		}
	}

	@include media-breakpoint-down(sm) {
		figure {
			img {
				height: 400px;
			}
		}

		.slick-arrow {
			top: 41.1%;
		}
	}
}

html[data-useragent*="rv:11.0"] {
    .image-slider figure {
        height: $imageSliderImageHeight;

        a {
        	display: block;
        	min-height: $imageSliderImageHeight;
        }
    }
}

@include media-breakpoint-down(md) {
	.single-post {
		.image-slider {
			margin-top: 5rem;
		}
	}
}