//******************************************************************************
// FOOTER
//******************************************************************************

.site-footer {
	font-size: 1.2rem;
	margin-top: 7.5rem;

	.container {
		padding-top: 3rem;
		padding-bottom: 3rem;
		border-top: 1px solid #ECECEC;
	}

	* {
		font-size: inherit;
	}

	p {
		display: inline-block;
		margin-bottom: 0;
	}

	&__siteby {
		display: flex;
		justify-content: flex-end;
	}

	.youtube-icon {
		font-size: 2.7rem;
		display: inline-block;
		position: relative;
		top: -6px;
		margin-right: 10px;
		color: red;

		svg {
			height: 1em;
    		width: 1em;
		}

		span {
			font-size: 12px;
		    font-weight: 400;
		    position: relative;
		    top: -9px;
		    margin-left: 4px;
			color: black;
		}

		&:hover {
			span {
				color: red;
			}
		}

		@include media-breakpoint-down(md) {
			span {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.menu-footer-menu-container {
			a {
				&:last-of-type {
					margin-left: 0;
				}
				margin-right: 1rem;
			}

			.menu-item {
				display: block;
				a {
					padding: 1rem 0;
					display: block;
				}
			}
		}

		p {
			margin-bottom: 1.5rem;
		}

		&__siteby {
			justify-content: flex-start;
			margin-top: 1.5rem;
		}

		* {
			font-size: 1.8rem;
		}
	}
}