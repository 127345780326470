//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

// Content
// -----------------------------------------------------------------------------
.document {

}

.main {
    flex: 1 0 auto;
}

.content {
    position: relative;
}

.m-t-20 {
    margin-top: 2rem;
}
.m-t-40 {
    margin-top: 4rem;
}
.m-b-40 {
    margin-bottom: 4rem;
}
.m-b-60 {
    margin-bottom: 6rem;
}
.m-r-20 {
    margin-right: 2rem;
}

.column-margin-top-first {
    margin-top: 9rem;

    @include media-breakpoint-down(md) {
        margin-top: 6rem;
    }
}

// Spacing
// -----------------------------------------------------------------------------
.spacer {
    margin: 8rem 0;
    display: block;
    width: 100%;
    height: 1px;
    background: #ECECEC;

    @include media-breakpoint-down(sm) {
        margin: 5.5rem 0;
    }
}

.custom-grid {
    &--align-bottom {
        align-items: flex-end;
    }

    &__col-55 {
        flex-basis: 55%;
        max-width: 55%;
    }
    &__col-50 {
        flex-basis: 50%;
        max-width: 50%;
    }
    &__col-45 {
        flex-basis: 45%;
        max-width: 45%;
    }
    &__col-40 {
        flex-basis: 40%;
        max-width: 40%;
    }

    @include media-breakpoint-down(md){
        &__col-55 {
            flex-basis: 100%;
            max-width: 100%;
        }
        &__col-50 {
            flex-basis: 100%;
            max-width: 100%;
        }
        &__col-45 {
            flex-basis: 100%;
            max-width: 100%;
        }
        &__col-40 {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .inner.block-padding {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.block-padding {
    padding: 8rem 6.5rem;

    @include media-breakpoint-down(md) {
        padding: 8rem 3rem 5rem;
    }
}

.faux-col-padding-left {
    padding-left: 8rem;

    @include media-breakpoint-down(md) {
        padding-left: 15px;
    }
}

#eu-cookie-bar {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 20px 15px;
    font-size: 1.6rem;

    a {
        color: #E9B491;
    }

    button {
        height: 20px;
        width: 20px;
        background-image: url('../assets/images/cross-white.png') !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 15px !important;
        background-color: transparent !important;
        padding: 0;
        font-size: 0;
        border: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-left: 2rem;
    }
}