//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
	font-size: 62.5%;
}

body {
	color: $font-clr;
    font-family: $font-family;
    font-weight: 300;
    font-size: 1.6rem;
    overflow-anchor: auto;
}

p {
	@include fluid-type(1.5rem, 1.6rem);
    margin-bottom: 1.7rem;
    line-height: 1.5;

    strong, b {
        font-weight: 700;
    }
}

a {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.btn,
.menu-btn a,
.frm_button_submit {
    display: inline-block;
    padding: 11px 23px;
    border: 2px solid $font-clr;
    border-radius: 24px;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .5px;
    transition: all .2s ease-in-out;
    &:hover {
        text-decoration: none;
        background-color: $font-clr;
        background-color: var(--scheme-clr);
        border-color: var(--scheme-clr);
        color: white;
    }

    &--link {
        border: none;
        display: inline-block;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 1.3rem;
        text-decoration: underline;
        color: var(--scheme-clr);
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .5px;
        &:hover {
            text-decoration: none;
            color: inherit;
        }

        .inline-open-arrow {
            height: 12px;
            margin-left: 10px;
        }
    }

    &--link--small {
        text-transform: none !important;
        letter-spacing: 0 !important;
        font-weight: 600 !important;
    }

    &--fill {
        background-color: $font-clr;
        background-color: var(--scheme-clr);
        border-color: var(--scheme-clr);
        color: white;

        &:hover {
            background-color: white;
            border-color: $font-clr;
            color: $font-clr;
        }
    }
}

.btn[download] {
    position: relative;
    &:after {
        content: "";
        background: url('../assets/images/download-black.png') no-repeat center;
        background-size: 100%;
        display: inline-block;
        height: 10px;
        width: 9px;
        margin-left: 8px;
        position: relative;
        top: 1px;
        transition: all .2s ease-in-out;
    }

    &:hover {
        color: white;

        &:after {
            content: "";
            background: url('../assets/images/download-white.png') no-repeat center;
            background-size: 100%;
        }
    }
}

figure figcaption,
.wp-caption-text {
    font-size: 1.6rem;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    margin-top: 1.7rem;
}

html[data-useragent*="rv:11.0"] {
    figure figcaption,
    .wp-caption-text {
        display: none;
    }
}


// OBJECTFIT
// -----------------------------------------------------------------------------

div {
    &.objectfit {
        //height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

html[data-useragent*="rv:11.0"] {

    div {
        &.objectfit {
            //height: 100%;
            img {
                -o-object-fit: cover;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    .compat-object-fit {
        background-size: cover;
        background-position: center center;
        img {
            display: none !important;
        }
    }

}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: relative;
        left: 0;
    }
}

.main-text-block {
    .iframe-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}