//******************************************************************************
// HEADER
//******************************************************************************
.site-header {
	width: 100%;
	background-color: #fff;
	.logo {
		height: 70px;
    	width: 186px;

        @include media-breakpoint-down(lg) {
            height: 62px;
            width: 160px;
        }

        @include media-breakpoint-down(sm) {
            height: 50px;
            width: 133px;
        }
	}
}

.language-changer {
	position: relative;

    @include media-breakpoint-down(md) {
        margin-left: auto;
    }
	
	&__btn.btn {
		background-color: transparent;
		padding-left: 1.7rem;
		padding-right: 1.7rem;
        margin-left: 1.5rem;
		color: $teal;
		border-color: $teal;
		position: relative;
		cursor: pointer;
		&:after {
			content: "";
			background: url('../assets/images/arrow-teal-down.png') no-repeat center;
			background-size: 100%;
			display: inline-block;
			height: 5px;
			width: 10px;
			margin-left: 8px;
			position: relative;
			top: -1px;
		}

        &:hover {
            color: white;
            border-color: $teal;
            background-color: $teal;

            &:after {
                content: "";
                background: url('../assets/images/arrow-white-down.png') no-repeat center;
                background-size: 100%;
            }
        }
	}

	.list-unstyled {
		padding: 2rem 3rem;
        border: 0;
        border-radius: 11px;
        margin-top: 0;
        background: #3D3D3D;
        transition: opacity .3s ease-in-out;
        opacity: 0;
        display: block;
        left: 50%;
        top:-99999px;
        position: absolute;
        z-index: 100;
        transform: translate(-50%, 0);

        &:before {
            content: "";
            width: 32px;
            height: 33px;
            background: #3d3d3d;
            border-radius: 5px;
            position: absolute;
            top: -15px;
            left: 49.4%;
            transform: rotate(-43deg) skew(5deg) translate(-50%, 0);
        }

        li {
        	position: relative;
            display: block;
            width: 100%;
            padding: 1.5rem 0;
            color: white;
            white-space: nowrap;
            border: 0;
            font-size: 1.8rem;
            line-height: 1;
            background-color: transparent;
            @include fade-transition(0.1s);
            
            &:hover {
                text-decoration: underline;

                a {
                	color: white;
                }
            }

            &:after {
                display: none;
            }
        }

        a {
        	display: block;
        }
	}

	.show {
		.list-unstyled {
			opacity: 1;
			top: 138%;
		}
	}

	.en-btn {
		appearance: none;
		background-color: transparent;
		border: none;
		color: white;
		padding: 0;
		margin: 0;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}