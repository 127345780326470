//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

// SECTION
// -----------------------------------------------------------------------------

.overflow-section {
	overflow: hidden;
}

$circleSmall: 28rem;
$circleBig: 45rem;
$circleOverlap: 6.5rem;
$circleGap: 2rem;
$circleDefaultHeight: 470px;

/* 
top-left : Top left
top-center : Top center
top-right : Top right
center-left : Centre left
center : Centre
center-right : Centre right
bottom-left : Bottom left
bottom-center : Bottom Centre
bottom-right : Bottom right
*/

.image-circle {
	position: relative;

	.circle {
		position: absolute;

		svg {
			width: 100%;
			height: auto;
		}
	}
	
	&--top-left {
		.circle {
			top: $circleGap;
			left: $circleGap;
		}
	}
	&--top-center {
		.circle {
			top: $circleGap;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
	&--top-right {
		.circle {
			top: $circleGap;
			right: $circleGap;
		}
	}
	&--center-left {
		.circle {
			top: 50%;
			left: $circleGap;
			transform: translate(0, -50%);
		}
	}
	&--center {
		.circle {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&--center-right {
		.circle {
			top: 50%;
			right: $circleGap;
			transform: translate(0, -50%);
		}
	}
	&--bottom-left {
		.circle {
			bottom: $circleGap;
			left: $circleGap;
		}
	}
	&--bottom-center {
		.circle {
			bottom: $circleGap;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
	&--bottom-right {
		.circle {
			bottom: $circleGap;
			right: $circleGap;
		}
	}

	&--circle-big .circle {
		height: $circleBig;
		width: $circleBig;
	}
	&--circle-small .circle {
		height: $circleSmall;
		width: $circleSmall;
	}

	&--has-caption {
		margin-bottom: 4.8rem;

		&.image-circle--center {
			.circle {
				top: calc(50% - 24px);
			}
		}
	}

	img {
		width: 100%;
		height: $circleDefaultHeight;
		object-fit: cover;
	}

	&.column-margin-top-first {
		margin-bottom: 8rem;
	}

	@include media-breakpoint-down(sm) {
		img {
			height: auto !important;
		}
	}

	&--NONE {
		svg {
			display: none !important;
		}
	}
}
.image-circle--circle-big.image-circle--top-center,
.image-circle--circle-big.image-circle--top-right,
.image-circle--circle-big.image-circle--top-left {
	padding-top: $circleOverlap;
	.circle {
		top: 0;
	}
}

.image-circle--circle-big.image-circle--bottom-center,
.image-circle--circle-big.image-circle--bottom-right,
.image-circle--circle-big.image-circle--bottom-left {
	padding-bottom: $circleOverlap;

	.circle {
		bottom: 0;
	}
}

html[data-useragent*="rv:11.0"] {
    .image-circle {
        height: $circleDefaultHeight;
    }
}

.circle[data-aos="animate-circle"] {
	path {
		fill: transparent;
	    stroke: $teal;
	    stroke-width: 8px;
	    opacity: 0;

		stroke-dasharray: 1420;  
		stroke-dashoffset: 0;
	}

	&.aos-animate {
		path {
			animation: animate-circle 1s ease-in-out alternate;
			animation-fill-mode: forwards;
			animation-delay:0.4s;
		}
	}
}
@keyframes animate-circle {
	from {
		stroke-dasharray: 0, 1420;
		opacity: 1;
	}
	to {
		stroke-dasharray: 1420, 1420;
		opacity: 1;
	}
}

html[data-useragent*="rv:11.0"] {
	.circle[data-aos="animate-circle"] {
		&.aos-animate {
			path {
				animation: animate-circle 1s ease-in-out alternate;
				animation-fill-mode: forwards;
				animation-delay:0.4s;
				stroke-dasharray: 1420, 1420 !important;
				opacity: 1 !important;
			}
		}
	}
}

.single-opportunities {
	.circle-animation__circle {
		position: relative;
		&:before {
			content: attr(data-circle-index);
			position: absolute;
			top: 0;
			left: 0;
			line-height: 1;    
			height: 6.3rem;
    		width: 6.3rem;
    		padding: 1.6rem 2rem;
			border: 5px solid $pink;
			border-radius: 50%;
			background-color: white;
			z-index: 2;
			font-weight: bold;
			font-size: 22px;
			color: $pink;

			@include media-breakpoint-down(md) {
				left: 1.5rem;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1124px) {
	.animated-circles {
		.circle-animation {
			display: inline-grid;
		}
	}

	.single-opportunities {
		.animated-circles {
			text-align: center;
		}
	}
}

@include media-breakpoint-down(sm) {
	.animated-circles {
		max-width: 421px;
		overflow: hidden;
	}
}

.community-opps-slider {
	&__content {
		h2 {
			margin-bottom: 0;
		}
		p {
			margin-top: 2rem;
			margin-bottom: 0;
		}
		.link-group {
			margin-bottom: 1.8rem;
			font-size: 1.3rem;
			font-weight: 600;

			&--controls {
				margin-top: 2.4rem;
				.btn {
					margin-right: 2rem;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.image-circle--circle-big {
			.circle {
				height: 30rem;
    			width: 30rem;
				
				&[data-aos=animate-circle] path {
    			    stroke-width: 13px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.image-circle {
			margin-bottom: 3rem;
		}
	}
}

.overflow-section {
	margin-top: -11rem;
	margin-bottom: 5rem;

	.tabs {
		padding-top: 16rem;

		&__mobile-content {
			display: none;
			text-align: center;

			@media(min-width: 769px){
				display: none !important;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		margin-top: -5rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 5rem;

		.tabs {
			padding-top: 0;
			margin-bottom: 5rem;
		}
	}

	@media(max-width: 769px){
		.tabs {
			display: block;

			.nav-tabs {
				width: 100%;
				max-width: 100%;
				padding-right: 0;
				display: block;
			}

			.nav-item {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 2rem;
				.nav-link {
					font-weight: 400;
				}
			}

			.tab-content {
				display: none;
			}
		}
	}
}

.tabs {
	display: flex;

	h4 {
		font-size: 2rem;
		margin-bottom: 2rem;
		line-height: 1.2;
		font-weight: 600;
	}
	
	.tab-content {
		display: flex;
		align-items: center;
		max-width: calc(100% - 31.5rem);
		flex-basis: calc(100% - 31.5rem);
	}

	.nav-tabs {
		border-bottom: none;
		padding-right: 6.5rem;
		max-width: 31.5rem;
		flex-basis: 31.5rem;
	}

	.nav-item {
		appearance: none;
		cursor: pointer;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
		min-height: 135px;
		min-width: 250px;
		max-width: 250px;
		position: relative;

		&.active {

			.nav-link {
				background-color: transparent;
				&:before {
					content: "";
					background: url('../assets/images/tabs-active-bg.png') no-repeat center center transparent;
					background-size: 135px;
					width: 135px;
					height: 135px;
					position: absolute;
					z-index: -1;
					display: block;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				span {
					color: $teal;
				}
			}
		}
	}

	.nav-link {
		max-width: 100%;
		line-height: 1.1;
		text-align: center;
		border: none;
		color: $font-clr;
		@include fluid-type(1.9rem, 2.8rem);
	}

	@include media-breakpoint-between(lg, xl) {
		.nav-tabs {
			padding-right: 2.5rem;
    		max-width: 27.5rem;
		}

		.nav-link {
			font-size: 2rem;
		}

		.nav-item {
			min-width: 209px;
    		max-width: 209px;
		}
	}
}

.text-block {
	h2 {
		max-width: 42rem;
		margin-bottom: 0;
	}
	h3 {
		margin-bottom: 0;
	}
	
	p {
		margin-bottom: 0;

		strong {
			font-weight: 400;
    		font-size: 1.8rem;
		}
	}

	> * + * {
		margin-top: 3rem;
	}

	ol {
		column-count: 2;
	}

	&--narrow {
		max-width: 52ch;

		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}
}
html[data-useragent*="rv:11.0"] {
	.text-block--narrow {
		max-width: 100%;
	}
}

.related-content-highlight {
	margin-bottom: 11rem;

	@include media-breakpoint-down(md) {
		margin-bottom: 7rem;

		figure {
			margin-bottom: 0;
		}

		.wp-caption-text {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		.image-circle {
			overflow: hidden;
		}
	}
}

html[data-useragent*="rv:11.0"] {
	.related-content-highlight {
		margin-bottom: 20rem;
	}

	div > svg.animate-drawing-svg {
		min-height: 300px;
	}
}

.circle-animation {
	display: grid;
	grid-template-columns: 270px 20px 270px;
	grid-template-rows: repeat(5, auto);
	margin-top: -75px;

	&__circle {
		width: 280px;
		height: 280px;
		border: 6px solid var(--scheme-clr);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		padding: 2.4rem;
		margin-bottom: 4rem;
		opacity: 0;
		transition-property: opacity,-webkit-transform;
	    transition-property: opacity,transform;
	    transition-property: opacity,transform,-webkit-transform;
	    transition-timing-function: ease;
        transition-duration: .5s;

		&:nth-of-type(1) {
			grid-column: 2 / 4;
			grid-row: 1 / 3;
			transition-delay: .5s;
		}

		&:nth-of-type(2) {
			grid-column: 1 / 3;
			grid-row: 2 / 4;
			transition-delay: 1s;
		}

		&:nth-of-type(3) {
			grid-column: 2 / 4;
			grid-row: 3 / 5;
			transition-delay: 1.5s;
		}

		&:nth-of-type(4) {
			grid-column: 1 / 3;
			grid-row: 4 / 6;
			transition-delay: 2s;
		}

		p:last-of-type {
			margin-bottom: 0;
		}

		&:nth-of-type(odd) {
        	transform: translate3d(100px,0,0);
		}

		&:nth-of-type(even) {
        	transform: translate3d(-100px,0,0);
		}
	}

	&__title {
		font-size: 2.4rem;
		line-height: 1.2;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: .8rem;
		color: var(--scheme-clr);
	}

	&__title-alt {
		font-size: 2.4rem;
		line-height: 1.2;
		font-weight: 600;
		margin-bottom: .8rem;
		color: var(--scheme-clr);
		a {
			text-decoration: underline;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.4rem;
		}
	}

	&--animated {
		.circle-animation__circle {
			opacity: 1;
		    -webkit-transform: translateZ(0);
		    transform: translateZ(0);
		}
	}

	@include media-breakpoint-between(md, lg) {
		grid-template-columns: 236px 20px 236px;

		&__circle {
			width: 260px;
    		height: 260px;

    		 p {
    		 	font-size: 1.5rem;
    		 }
		}

		&__title {
			font-size: 2rem;
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;
		margin-top: 4rem;

		&__circle {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

html[data-useragent*="rv:11.0"] {
	.circle-animation {
		margin-top: 4rem;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.circle-animation__circle {
		align-items: stretch;
		width: 26rem;
		height: 26rem;
		margin-left: 5px;
		margin-right: 5px;
		border: 6px solid $teal;

		p {
			font-size: 1.5rem;
		}
	}
	.circle-animation__icon {
		display: none;
	}
	.scheme--pink {
		.circle-animation__circle {
			border: 6px solid $pink;
		}
	}
	.circle-animation__title {
		font-size: 1.7rem;
	}
}

.main-text-block {
	> h4:first-child {
		margin-top: 0;
	}

	a:not(.btn),
	a:not(.btn--link) {
		text-decoration: underline;

		&:hover {
			text-decoration: underline solid $teal;
		}
	}
}