//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

// Base Inputs
// -----------------------------------------------------------------------------
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    width: 100%;
    min-width: 100px;
    height: 44px;
    font-weight: 700;
    font-size: 12px;
    border: 0;
    padding: 0 18px;
}

input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}

.grid-area {
    select {
        @supports(display: grid) {
            appearance: none;
            background: url('../assets/images/select-arrow.svg') no-repeat calc(100% - 20px) center;
            background-size: 9px;
        }
        @supports (-ms-ime-align:auto) {
            background: none !important;
            appearance: menulist !important;
            padding-right: 23px !important;
        }

        padding: 11px 40px 11px 23px;
        border: 2px solid $teal;
        border-radius: 24px;
        color: $teal;
        text-transform: uppercase;
        letter-spacing: .5px;
        width: calc(100% + 5px);

        option {
            font-size: 14px;
            color: #222;
            font-weight: normal;
        }
    }
}

html[data-useragent*="rv:11.0"] {
    .grid-area select {
        padding: 11px 23px;
    }
}

.frm_verify {
    display: none;
}

.searchandfilter {
    ul {
        display: flex;
        flex-wrap: wrap;

        > li + li {
            margin-left: 3rem;
        }
    }

    input[type='text'] {
        padding: 11px 40px 11px 23px;
        border: 2px solid $teal;
        border-radius: 24px;
        font-size: 16px;
        font-weight: normal;
    }

    .sf-field-reset input[type='submit'] {
        @extend .btn--link;
        background: transparent;
        cursor: pointer;
        height: 44px;
        color: $font-clr;
    }

    .sf-field-submit input[type='submit'] {
        @extend .btn;
        background: transparent;
        cursor: pointer;
        height: 44px;
    }

    @include media-breakpoint-down(sm) {
        ul {
            flex-wrap: nowrap;
            flex-direction: column;

            > li + li {
                margin-left: 0;
            }
        }
    }
}

// Placeholder
// -----------------------------------------------------------------------------
::-webkit-input-placeholder {
    color: #555;
}
::-moz-placeholder {
    color: #555;
}
:-ms-input-placeholder {
    color: #555;
}
:-moz-placeholder {
    color: #555;
}