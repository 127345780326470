//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

.bg-blue, .bg-teal {
    background-color: $teal;
    color: white;
}
.bg-light-blue, .bg-light-teal {
    background-color: rgba($teal, .2);

    .btn {
        border-color: $font-clr;
        &:hover {
            background-color: $font-clr;
            border-color: $font-clr;
        }
    }
}

.bg-pink, .bg-fuchsia {
    background-color: $fuchsia;
    color: white;
}
.bg-light-pink, .bg-light-fuchsia {
    background-color: rgba($fuchsia, .2);

    .btn {
        border-color: inherit;
    }
}

.bg-orange, .bg-peach {
    background-color: #E8A87C;
    color: $dark-clr;

    h1 {
        color: #060606;
    }
}
.bg-light-orange, .bg-light-peach {
    background-color: rgba($peach, .2);
    color: #3D3D3D;
    
    .btn {
        border-color: inherit;
        &:hover {
            background-color: $font-clr;
            border-color: $font-clr;
        }
    }

    .stories-slider__slide &,
    .single-opportunities .related-content-highlight & {
        background: white;
        border: 8px solid #E9B592;
        border-right: none;

        @media(max-width: 1124px) {
            border-right: 8px solid #E9B592;
        }

        .btn {
            border-color: $font-clr;
        }
    }

    .single-opportunities .related-content-highlight & {
        border-right: 8px solid #E9B592;
        position: relative;
        margin-left: -8px;

        @media(max-width: 1124px) {
            margin-left: 0;
        }
    }
}

.scheme {
    &--orange, &--peach {
		--scheme-clr: $orange;
    }
    &--pink, &--fuchsia {
        --scheme-clr: $pink;
    }
    &--blue, &--teal {
        --scheme-clr: $blue;
    }
}

.circle[data-aos="animate-circle"] path {
	stroke: var(--scheme-clr);
}
.scheme--orange,
.scheme--peach {
    .circle[data-aos="animate-circle"] path {
        stroke: $lighter-peach;
    }
}

h1,
h2,
h3 {
    span {
        color: var(--scheme-clr);
    }
}