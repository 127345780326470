//******************************************************************************
// ARTICLES
//******************************************************************************

.additional-content-block {
	margin-bottom: 12rem;
	
	&__posts {
		padding: 40px 0 0 40px;

		&:before {
			content: "";
			height: 240px;
			width: calc(100% - 40px);
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			background-color: $pink;
		}
	}

	&__twitter {
		@include media-breakpoint-up(md) {
			padding-left: 5rem;
		}

		> img {
			margin-top: -1rem;
			margin-bottom: 2rem;
		}

		#ctf {
			.ctf-item {
				padding: 0;
			}

			.ctf-tweet-text {
				color: $orange;
				@include fluid-type(1.8rem, 2.8rem);
				font-weight: 300;
				margin-bottom: 2rem !important;
				line-height: 1.1;

				br + br {
					display: none;
				}
			}

			.ctf-tc-summary-info {
				color: $font-clr;
			}

			.ctf-twitter-card .ctf-tc-url {
				opacity: 1;
				text-decoration: underline;
			}

			.ctf-retweet-icon {
				background: $orange;
			}
		}

		.btn {
			border-color: $orange;
			color: $orange;

			&:hover {
				background-color: $orange;
				border-color: $orange;
				color: white;
			}
		}
	}

	@include media-breakpoint-down(md) {
		overflow: hidden;

		.post-preview {
			margin-bottom: 5rem;
		}

		&__posts {
			padding: 40px 40px 0 40px;
			&:before {
				width: 100vw;
			}
		}
	}
}

.post-preview {
	position: relative;
	z-index: 2;

	h3 {
		@include fluid-type(2.3rem, 2.8rem);
		font-family: $font-family;
		line-height: 1.1;
		text-transform: none;
	}

	h3, p {
		margin-bottom: 0;
	}

	> * + * {
		margin-top: 1.6rem;
	}

	&__image-container.objectfit {
		height: 240px;
	}

	&__meta {
		color: var(--scheme-clr);
		font-weight: 600;
		font-size: 1.3rem;
		a {
			@extend .btn--link;
			@extend .btn--link--small;
		}
	}
}

html[data-useragent*="rv:11.0"] {
	.post-preview__image-container.objectfit {
		a {
			display: block;
			min-height: 100%;
		}
	}
}

.grid-area {
	.post-preview {
		padding: 40px 0 55px 40px;

		&:before {
			content: "";
			height: 24rem;
			width: calc(100% - 40px);
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			opacity: .5;
		}

		&__image-container.objectfit {
			height: 27rem;
			position: relative;
    		z-index: 2;
    		margin-bottom: 3.8rem;
		}

		&__meta {
			.btn--link {
				font-size: 1.3rem !important;
			}
		}

		@include media-breakpoint-down(md) {
			&:before {
				height: 20rem;
			}
			&__image-container.objectfit {
				height: 20rem;
			}
		}
	}

	&__item {
		&:nth-of-type(3n+1) {
			.post-preview:before {
				background-color: $teal;
			}
		}

		&:nth-of-type(3n+2) {
			.post-preview:before {
				background-color: $orange;
			}
		}

		&:nth-of-type(3n+3) {
			.post-preview:before {
				background-color: $pink;
			}
		}

		.btn--link {
			font-size: 1.2rem;
		}
	}

	&__highlight {
		.inner {
			padding: 3.5rem;
		}

		.objectfit {
			height: 29.5rem;
		}

		.btn {
			border-color: white;

			&:hover {
				background-color: white;
				border-color: white;
				color: #616161;
			}
		}
	}

	&--standard {
		.post-preview {
			padding-top: 0;
			padding-right: 15px;
    		padding-left: 15px;
    		padding-bottom: 0;

    		h4 {
    			line-height: 1.1;
    		}
		}
		.post-preview__image-container.objectfit {
			height: 21rem;
			margin-bottom: 2.8rem;
		}

		@include media-breakpoint-down(md) {
			.post-preview {
				margin-bottom: 5.5rem;
			}
		}
	}
}

.big-cta-block {
	margin-top: 2rem;
	.container {
		padding-top: 8rem;
		border-top: 1px solid #ECECEC;
	}

	p {
		margin-bottom: 3rem;
	}
}

.pagination {
	.page-numbers {
		padding: 3px 8px;
		border: 1px solid $font-clr;
		display: inline-block;
	    text-align: center;
	    margin-right: .8rem;
	    font-size: 12px;
	    font-weight: 400;

		&:hover {
			background-color: rgba($teal, .1);
	    	text-decoration: none;
		}

		&.current {
			border-color: $teal;
			background-color: $teal;
			color: white;

			&:hover {
				background-color: $teal;
			}
		}
	}
}

.alignright {
	float: right;
	padding: 0px 0px 10px 0px;
}
.alignleft {
	float: left;
	padding: 0px 10px 0px 0px;
}
.aligncenter {
	clear: both; 
	display: block; 
	margin-left: auto; 
	margin-right: auto;
	padding: 10px;
}
.alignnone {
	padding: 10px 0;
}