//******************************************************************************
// TYPOGRAPHY
//******************************************************************************


// Headings
// -----------------------------------------------------------------------------
// Define a reusable & flexible class for each heading, which can be used
// everywhere in the page to replicate the style of the headings while keeping
// a clean structure.
// http://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/

p.larger {
	font-size: 2rem;
	line-height: 1.2;
}

h1,
h2,
h3,
blockquote {
	font-family: 'pironregular';
	text-transform: uppercase;
	line-height: 1.1;
	font-weight: normal;
}

h1, .alpha {
	@include fluid-type(3.9rem, 4.9rem);
	//line-height: 0.95;
	line-height: 1;

	span {
		color: #060606;
	}
}

h2, .beta {
	@include fluid-type(3.5rem, 4.6rem);
	line-height: 1.1;
}

h3, .gamma {
	@include fluid-type(3.2rem, 3.6rem);
	line-height: 1.1;
	margin-bottom: .5em;
}

h4, .delta {
	@include fluid-type(2.3rem, 2.8rem);
	line-height: 1.3;
	font-weight: 400;
	margin-bottom: .5em;
}

h5, .epsilon {
	font-size: 1.3rem;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1;
	letter-spacing: .5px;
}

h6, .zeta {

}


blockquote {
	margin: 3.4rem 0;
	background-color: $pink;
	position: relative;
	padding: 8.5rem 0 0 9.5rem;

	&:after {
		content: "";
		position: absolute;
		background-color: white;
		height: calc(100% - 4rem);
		width: calc(100% - 4rem);
		right: 0;
		bottom: 0;
	}

	* {
		position: relative;
		z-index: 1;
		font-size: 2.6rem;
		line-height: 1.2;
		color: $pink;
	}
}

.ul {
	&--no-style {
		margin: 0;
		padding: 0;
		list-style: none;
		> * + * {
			margin-top: 2.2rem;
		}
	}

	&--phone-numbers {
		font-size: 2.8rem;
		font-weight: 400;
		margin-top: 2rem;

		li {
			width: 100%;
			display: flex;
			flex-direction: row;
			
			span {
				display: block;
				border-bottom: 2px dotted $font-clr;
				flex-grow: 2;
				margin: 0 14px 0 7px;
				position: relative;
				top: -11px;
			}

			a {
				margin-left: auto;
				position: relative;
				&:before {
					content: "";
					background: url('../assets/images/phone.png') no-repeat center;
					background-size: 100%;
					display: inline-block;
					height: 23px;
					width: 21px;
					position: relative;
					top: 3px;
    				left: -6px;
				}
			}
		}

		@include media-breakpoint-down(md) {
			font-size: 1.8rem;
		}

		@include media-breakpoint-down(sm) {
			li {
				display: block;
				span {
					display: none;
				}
			}
		}
	}
}

ol, ul {
	padding-left: 20px;
}