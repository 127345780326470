//******************************************************************************
// PAGES
// Common styles shared by multiple pages
// Unless you're working on very large site or application, most of the
// page-specific code should go in here.
//******************************************************************************

// PAGE > 404
// -----------------------------------------------------------------------------

.content-404 {
	h1 {
		@include fluid-type(6.5rem, 10.5rem);
		margin-bottom: .1em;
	}

	h2 {
		margin-bottom: 2rem;
	}

	p {
		margin-bottom: 3rem;
	}

	.btn {
		color: white !important;
		border-color: white !important;

		&:hover {
			border-color: white !important;
			background-color: white !important;
			color: $pink !important;
		}
	}
}