#content .hero {
	overflow: hidden;

	h1 {
		margin-bottom: 4rem;

		@include media-breakpoint-down(md) {
			font-size: 3.9rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 3.4rem;
		}
	}

	strong, b {
		font-weight: 700;
	}

	p {
		max-width: 100%;
	}

	.btn {
		border-color: #3D3D3D;
		color: #3D3D3D;
		margin-top: 2rem;

		&:hover {
			background: #3D3D3D;
			color: white;
		}
	}

	&__content {
		position: relative;
		width: calc(100% + 10rem);
		left: -2rem;

		.aos-init {
			p:last-of-type {
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-down(md) {
			left: 0;
			width: 100%;
		}
	}

	&__top {
		.hero__content {
			padding: 8.2rem 10rem;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column-reverse !important;

			.hero__content {
				padding: 7rem;
			}
		}

		@include media-breakpoint-down(sm) {
			.hero__content {
				padding: 5rem 3rem;
				min-height: 0;
				z-index: 5;
			}
		}
	}

	&__bottom {
		.hero__content {
			padding: 5rem 10rem;

			p:last-of-type {
				margin-bottom: 0;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 4rem;
			}
		}

		.hero__bottom__slider-container {
			margin: -4rem 0 4rem 0;

			@include media-breakpoint-down(md) {
				margin: 0;
			}
		}
	}

	&__image {
		position: relative;
		width: calc(100% + 12rem);
		left: -8rem;
		img {
			height: 47rem;
		}

		@include media-breakpoint-down(md) {
			left: 0;
			width: 100%;
		}
	}

	&__slider {
		margin-top: 2rem;

		.slider__slide {
			p {
				margin-bottom: 0;
			}
			> * + * {
				margin-top: 2.2rem;
			}
		}
	}

	&--default {
		position: relative;
		overflow: visible;
		margin: 4.5rem 0 8.5rem;
		.container {
			padding-top: 7rem;
			padding-bottom: 7rem;
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
				height: 100%;
				width: 50vw;
				display: block;
				background: inherit;
			}
			&:before {
				content: "";
				position: absolute;
				margin-left: -8rem;
				top: 0;
				z-index: -1;
				height: 100%;
				width: 50vw;
				display: block;
				background: inherit;
			}
		}

		h1 {
			margin-bottom: 0;
		}
	}

	&--reversed {
		margin-bottom: 5rem;

		.hero__content {
			left: -8rem;
			margin-top: 5rem;
			margin-bottom: 5rem;
			min-height: 0;
			padding: 11.2rem 7rem 11.2rem 8rem;

			.text-right {
				display: flex;
				flex-direction: column;
	    		justify-content: flex-end;
	    		align-items: flex-end;
			}

			.btn {
				text-align: center;
			}

			h1 {
				margin-bottom: 0;
			}

			p {
				margin-top: 1.5rem;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			@include media-breakpoint-between(md, lg) {
				h1 {
					font-size: 3.9rem;
				}
			}
		}
		.hero__image {
			width: calc(100% + 3rem);
    		left: -3rem;
		}

		@include media-breakpoint-down(md) {
			margin-top: 2rem;

			.hero__content,
			.hero__image {
				left: 0;
				width: 100%;
				margin-top: 0;
				margin-bottom: 0;
			}

			.hero__content {
				padding: 7rem;

				h1 {
					margin-bottom: 2rem;
				}
				
				.text-right {
					text-align: left !important;
					display: block;
				}
			}

			.image-circle--circle-big.image-circle--bottom-center {
				padding-bottom: 0;
			}

			figure {
				margin-bottom: 0;
			}

			.wp-caption-text {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			.hero__content {
				padding: 5rem 3rem;
			}

			.image-circle--circle-big {
				.circle {
					height: 30rem;
	    			width: 30rem;
					
					&[data-aos=animate-circle] path {
	    			    stroke-width: 13px;
					}
				}
			}
		}
	}

	&--contained {
		.hero__content {
			left: 0;
			width: 100%;
		}
		.hero__image {
			left: 0;
			width: 100%;
		}
	}

	&--home {
		margin-bottom: 7rem;

		h1 {
			font-size: 5.6rem;
			margin-bottom: 0;
		}

		a:not(.btn) {
			text-decoration: underline;
		}

		.hero__image {
			margin-top: 2rem;
		}

		.hero__top {
			.hero__content {
				min-height: 65rem;
				
				p:last-of-type {
					margin-bottom: 3px;
				}
			}

			.image-circle img {
				height: 48rem;
			}
		}

		.hero__bottom {
			.link-group {
				margin-top: 3rem;
			}
		}

		@include media-breakpoint-down(lg) {
			h1 {
				margin-bottom: 4rem;
				font-size: 4.6rem;
			}

			.hero__top {
				.hero__content {
					padding: 9.2rem 10rem;
				}
			}

			.ul--no-style {
				margin-top: 3rem;
			}
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-down(sm) {
			.hero__top {
				.hero__content {
					padding: 5rem 3rem 5rem;
					min-height: 0;

					&--has-notice {
						padding: 10rem 3rem 5rem;
					}
				}

				.image-circle {
					img {
						height: 34rem;
					}

					.circle {
						height: 35rem;
    					width: 35rem;
					}
				}
			}

			h1 {
				font-size: 3.6rem;
			}

			.hero__bottom {
				.hero__content {
					padding: 5rem 3rem;
				}
			}
		}
	}

	&--contact {
		.hero__content {
			padding: 9rem;

			h1 {
				margin-bottom: 3.5rem;
			}

			p {
				margin-bottom: 3rem;
			}

			li {
				display: flex;
				flex-direction: row;
				align-items: baseline;
			}

			img {
				position: relative;
				top: 3px;
			}

			a {
				font-size: 2.4rem;
				font-weight: 600;
				padding-left: 1.8rem;
				vertical-align: middle;
				display: inline-block;
			}
			
			ul {
				margin-top: 3rem;
			}

			@include media-breakpoint-down(md) {
				padding: 5rem 3rem;
			}

			@include media-breakpoint-down(sm) {
				a[href^="mailto"] {
					word-break: break-all;
				}
			}
		}
	}

	&--general-text {
		margin-bottom: 6rem;
	}

	.slider-arrow {
		display: none;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 8rem;
	}

	&--blog {
		margin-bottom: 6.5rem;
	}

	.ul--no-style {
		.btn {
			margin-top: 0;
		}
	}

	.notice {
		position: absolute;
	    top: 0;
	    left: 0;
	    padding: 1rem;
	    padding-left: 10.2rem;
	    width: 100%;
	    background: $pink;
	    color: #ffffff;
	    //text-align: center;
	}
}

@include media-breakpoint-up(md){
	.single-stories {
		.hero--reversed {
			h1 {
				font-size: 3.6rem;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.single-stories {
		.hero--reversed {
			margin-bottom: 5rem;
		}
	}
}

@include media-breakpoint-down(sm) {
	#content .hero.hero--default {
    	margin-top: 2rem;
		margin-bottom: 6rem;
		.container {
			padding-top: 4rem;
			padding-bottom: 3.5rem;
		}
	}
}


html[data-useragent*="rv:11.0"] {
    .hero .hero__image.objectfit {
        margin-top: 6rem !important;
    }
}