/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1124px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 480px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1124px) {
    .container {
      max-width: 1100px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1380px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1124px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 479.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1123.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1124px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1124px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1124px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1124px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1124px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1124px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: local("Nunito Light"), local("Nunito-Light"), url("../assets/fonts/nunito-v12-latin-300.woff2") format("woff2"), url("../assets/fonts/nunito-v12-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../assets/fonts/nunito-v12-latin-regular.woff2") format("woff2"), url("../assets/fonts/nunito-v12-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* nunito-semi-bold - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../assets/fonts/nunito-v12-latin-600.woff2") format("woff2"), url("../assets/fonts/nunito-v12-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../assets/fonts/nunito-v12-latin-700.woff2") format("woff2"), url("../assets/fonts/nunito-v12-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: local("Nunito ExtraBold"), local("Nunito-ExtraBold"), url("../assets/fonts/nunito-v12-latin-800.woff2") format("woff2"), url("../assets/fonts/nunito-v12-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* Piron */
@font-face {
  font-family: 'pironregular';
  src: url("../assets/fonts/piron-webfont.woff2") format("woff2"), url("../assets/fonts/piron-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'pironitalic';
  src: url("../assets/fonts/piron_italic-webfont.woff2") format("woff2"), url("../assets/fonts/piron_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

.site-header {
  width: 100%;
  background-color: #fff; }
  .site-header .logo {
    height: 70px;
    width: 186px; }
    @media (max-width: 1439.98px) {
      .site-header .logo {
        height: 62px;
        width: 160px; } }
    @media (max-width: 767.98px) {
      .site-header .logo {
        height: 50px;
        width: 133px; } }

.language-changer {
  position: relative; }
  @media (max-width: 1123.98px) {
    .language-changer {
      margin-left: auto; } }
  .language-changer__btn.btn, .searchandfilter .sf-field-submit input.language-changer__btn[type='submit'] {
    background-color: transparent;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    margin-left: 1.5rem;
    color: #128484;
    border-color: #128484;
    position: relative;
    cursor: pointer; }
    .language-changer__btn.btn:after, .searchandfilter .sf-field-submit input.language-changer__btn[type='submit']:after {
      content: "";
      background: url("../assets/images/arrow-teal-down.png") no-repeat center;
      background-size: 100%;
      display: inline-block;
      height: 5px;
      width: 10px;
      margin-left: 8px;
      position: relative;
      top: -1px; }
    .language-changer__btn.btn:hover, .searchandfilter .sf-field-submit input.language-changer__btn[type='submit']:hover {
      color: white;
      border-color: #128484;
      background-color: #128484; }
      .language-changer__btn.btn:hover:after, .searchandfilter .sf-field-submit input.language-changer__btn[type='submit']:hover:after {
        content: "";
        background: url("../assets/images/arrow-white-down.png") no-repeat center;
        background-size: 100%; }
  .language-changer .list-unstyled {
    padding: 2rem 3rem;
    border: 0;
    border-radius: 11px;
    margin-top: 0;
    background: #3D3D3D;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    display: block;
    left: 50%;
    top: -99999px;
    position: absolute;
    z-index: 100;
    transform: translate(-50%, 0); }
    .language-changer .list-unstyled:before {
      content: "";
      width: 32px;
      height: 33px;
      background: #3d3d3d;
      border-radius: 5px;
      position: absolute;
      top: -15px;
      left: 49.4%;
      transform: rotate(-43deg) skew(5deg) translate(-50%, 0); }
    .language-changer .list-unstyled li {
      position: relative;
      display: block;
      width: 100%;
      padding: 1.5rem 0;
      color: white;
      white-space: nowrap;
      border: 0;
      font-size: 1.8rem;
      line-height: 1;
      background-color: transparent;
      transition-property: all;
      transition-duration: 0.1s;
      transition-timing-function: ease-out; }
      .language-changer .list-unstyled li:hover {
        text-decoration: underline; }
        .language-changer .list-unstyled li:hover a {
          color: white; }
      .language-changer .list-unstyled li:after {
        display: none; }
    .language-changer .list-unstyled a {
      display: block; }
  .language-changer .show .list-unstyled {
    opacity: 1;
    top: 138%; }
  .language-changer .en-btn {
    appearance: none;
    background-color: transparent;
    border: none;
    color: white;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    .language-changer .en-btn:hover {
      text-decoration: underline; }

.main-nav .navbar {
  width: 100%;
  padding: 1rem 1.5rem; }
  @media (max-width: 1123.98px) {
    .main-nav .navbar {
      justify-content: flex-start; } }
  .main-nav .navbar .navbar--primary .menu-item {
    position: relative; }
    .main-nav .navbar .navbar--primary .menu-item .nav-link {
      padding: 1.5rem 0.5rem;
      margin: 0 1.7rem;
      color: inherit;
      font-size: 1.6rem;
      font-weight: 400;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease-out; }
      .main-nav .navbar .navbar--primary .menu-item .nav-link:after {
        display: none; }
      @media (max-width: 1439.98px) {
        .main-nav .navbar .navbar--primary .menu-item .nav-link {
          padding: 1.5rem 0rem; } }
      .main-nav .navbar .navbar--primary .menu-item .nav-link:hover {
        color: #128484; }
    .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle {
      display: none; }
    @media (min-width: 1124px) {
      .main-nav .navbar .navbar--primary .menu-item .dropdown-menu {
        padding: 3rem 5rem;
        border: 0;
        border-radius: 11px;
        margin-top: 0;
        background: #3d3d3d;
        transition: opacity .3s ease-in-out;
        opacity: 0;
        display: block;
        left: 50%;
        top: -99999px;
        transform: translate(-50%, 0); }
        .main-nav .navbar .navbar--primary .menu-item .dropdown-menu:before {
          content: "";
          width: 32px;
          height: 33px;
          background: #3d3d3d;
          border-radius: 5px;
          position: absolute;
          top: -15px;
          left: 49.4%;
          transform: rotate(-43deg) skew(5deg) translate(-50%, 0); }
        .main-nav .navbar .navbar--primary .menu-item .dropdown-menu .dropdown-item {
          position: relative;
          display: block;
          width: 100%;
          padding: 1.5rem 0;
          color: white;
          white-space: nowrap;
          border: 0;
          font-size: 1.8rem;
          line-height: 1;
          background-color: transparent;
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-out; }
          .main-nav .navbar .navbar--primary .menu-item .dropdown-menu .dropdown-item:hover {
            text-decoration: underline; }
          .main-nav .navbar .navbar--primary .menu-item .dropdown-menu .dropdown-item:after {
            display: none; } }
    @media (min-width: 1124px) {
      .main-nav .navbar .navbar--primary .menu-item:hover .dropdown-menu {
        display: block;
        opacity: 1;
        position: absolute;
        top: 98%; } }
    @media (max-width: 1123.98px) {
      .main-nav .navbar .navbar--primary .menu-item:hover .nav-link {
        color: #000; }
      .main-nav .navbar .navbar--primary .menu-item:hover .menu-dropdown-toggle .menu-dropdown-toggle-icon:before, .main-nav .navbar .navbar--primary .menu-item:hover .menu-dropdown-toggle .menu-dropdown-toggle-icon:after {
        background: #fff; }
      .main-nav .navbar .navbar--primary .menu-item .nav-link {
        padding: 0.2rem 0; }
      .main-nav .navbar .navbar--primary .menu-item .dropdown-menu.open {
        position: relative;
        display: block;
        padding: 0;
        background: transparent; }
      .main-nav .navbar .navbar--primary .menu-item .dropdown-menu .dropdown-item {
        padding: 0.5rem 0; }
        .main-nav .navbar .navbar--primary .menu-item .dropdown-menu .dropdown-item:after {
          right: 0; }
      .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle {
        padding: 1rem;
        border: 0;
        border-radius: 0;
        position: absolute;
        top: -0.2rem;
        right: -1rem;
        display: block;
        background: transparent; }
        .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle .menu-dropdown-toggle-icon {
          position: relative;
          cursor: pointer;
          height: 3rem;
          width: 2rem;
          display: block; }
          .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle .menu-dropdown-toggle-icon:before, .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle .menu-dropdown-toggle-icon:after {
            background: #fff;
            content: '';
            display: block;
            width: 2rem;
            height: .3rem;
            top: 1.35rem;
            position: absolute;
            transition-property: all;
            transition-duration: 0.3s;
            transition-timing-function: ease-out; }
          .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle .menu-dropdown-toggle-icon:before {
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
          .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle .menu-dropdown-toggle-icon:after {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
        .main-nav .navbar .navbar--primary .menu-item .menu-dropdown-toggle.open .menu-dropdown-toggle-icon:after {
          display: none; } }
  @media (min-width: 1124px) {
    .main-nav .navbar .navbar--primary > .menu-item.current_page_item, .main-nav .navbar .navbar--primary > .menu-item.current-menu-parent, .main-nav .navbar .navbar--primary > .menu-item.current-menu-item {
      position: relative; }
      .main-nav .navbar .navbar--primary > .menu-item.current_page_item:before, .main-nav .navbar .navbar--primary > .menu-item.current-menu-parent:before, .main-nav .navbar .navbar--primary > .menu-item.current-menu-item:before {
        content: "";
        height: 44px;
        width: 44px;
        border-radius: 50%;
        display: block;
        background-color: white;
        border: 3px solid rgba(18, 132, 132, 0.3);
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .main-nav .navbar .navbar--primary > .menu-item.current_page_item .nav-link, .main-nav .navbar .navbar--primary > .menu-item.current-menu-parent .nav-link, .main-nav .navbar .navbar--primary > .menu-item.current-menu-item .nav-link {
        position: relative;
        z-index: 1; } }
  @media (max-width: 1123.98px) {
    .main-nav .navbar .navbar-collapse {
      order: 3;
      color: white;
      position: relative; }
      .main-nav .navbar .navbar-collapse .navbar--primary {
        background-color: #3d3d3d;
        padding: 3rem 5rem;
        border: 0;
        border-radius: 11px;
        position: relative;
        margin-top: 3rem; }
        .main-nav .navbar .navbar-collapse .navbar--primary:before {
          content: "";
          width: 32px;
          height: 33px;
          background: #3d3d3d;
          border-radius: 5px;
          position: absolute;
          top: -15px;
          left: 49.4%;
          transform: rotate(-43deg) skew(5deg) translate(-50%, 0); }
        .main-nav .navbar .navbar-collapse .navbar--primary .menu-item .nav-link {
          font-size: 2rem;
          padding: 1rem 0;
          margin: 0; }
        .main-nav .navbar .navbar-collapse .navbar--primary .menu-item:hover .nav-link {
          color: white;
          text-decoration: underline; }
      .main-nav .navbar .navbar-collapse .dropdown-menu {
        border: none;
        font-size: inherit;
        padding: 0 2rem 2rem !important; }
      .main-nav .navbar .navbar-collapse .dropdown-item {
        color: white; }
        .main-nav .navbar .navbar-collapse .dropdown-item:hover {
          background-color: transparent;
          text-decoration: underline; } }
  @media (max-width: 767.98px) {
    .main-nav .navbar .navbar-collapse .navbar--primary {
      padding: 2rem 2rem; } }

.navbar-toggler.btn, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] {
  display: none; }
  @media (max-width: 1123.98px) {
    .navbar-toggler.btn, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] {
      border-color: #128484;
      color: #128484;
      display: inline-block;
      margin-left: 1.8rem; }
      .navbar-toggler.btn .navbar-toggler-icon, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] .navbar-toggler-icon {
        position: relative;
        display: inline-block;
        width: 1.5rem;
        height: 0.15rem;
        background: #000;
        transition: all .2s ease-in-out; }
        .navbar-toggler.btn .navbar-toggler-icon:before, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] .navbar-toggler-icon:before, .navbar-toggler.btn .navbar-toggler-icon:after, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] .navbar-toggler-icon:after {
          background: #000;
          content: '';
          display: block;
          width: 1.5rem;
          height: 0.15rem;
          position: absolute;
          transition: all .2s ease-in-out; }
        .navbar-toggler.btn .navbar-toggler-icon:before, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] .navbar-toggler-icon:before {
          top: -0.5rem; }
        .navbar-toggler.btn .navbar-toggler-icon:after, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] .navbar-toggler-icon:after {
          top: 0.5rem; }
      .navbar-toggler.btn[aria-expanded="true"] .navbar-toggler-icon, .searchandfilter .sf-field-submit input.navbar-toggler[aria-expanded="true"][type='submit'] .navbar-toggler-icon {
        background: transparent; }
        .navbar-toggler.btn[aria-expanded="true"] .navbar-toggler-icon:before, .searchandfilter .sf-field-submit input.navbar-toggler[aria-expanded="true"][type='submit'] .navbar-toggler-icon:before, .navbar-toggler.btn[aria-expanded="true"] .navbar-toggler-icon:after, .searchandfilter .sf-field-submit input.navbar-toggler[aria-expanded="true"][type='submit'] .navbar-toggler-icon:after {
          top: 0;
          height: 0.20rem; }
        .navbar-toggler.btn[aria-expanded="true"] .navbar-toggler-icon:before, .searchandfilter .sf-field-submit input.navbar-toggler[aria-expanded="true"][type='submit'] .navbar-toggler-icon:before {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        .navbar-toggler.btn[aria-expanded="true"] .navbar-toggler-icon:after, .searchandfilter .sf-field-submit input.navbar-toggler[aria-expanded="true"][type='submit'] .navbar-toggler-icon:after {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); } }
  .navbar-toggler.btn:hover, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit']:hover {
    background-color: #128484;
    border-color: #128484;
    color: white; }
  @media (max-width: 767.98px) {
    .navbar-toggler.btn, .searchandfilter .sf-field-submit input.navbar-toggler[type='submit'] {
      margin-left: 0.8rem; } }

/* FOOTER NAVIGATION */
.menu-footer-menu-container {
  display: inline-block; }
  .menu-footer-menu-container .menu {
    padding: 0;
    margin: 0;
    list-style: none; }
    .menu-footer-menu-container .menu .menu-item {
      display: inline-block; }
  .menu-footer-menu-container a {
    text-decoration: underline; }
    .menu-footer-menu-container a:hover {
      text-decoration: none; }
    .menu-footer-menu-container a:last-of-type {
      margin-left: 10px; }

/* Secondary nav */
.navbar--secondary li {
  position: relative; }

.navbar--secondary.navbar-nav {
  flex-direction: row; }

@media (max-width: 767.98px) {
  .navbar--secondary.navbar-nav {
    flex-direction: column; } }

.navbar--secondary .menu-item-has-children .menu-dropdown-toggle {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer; }

.navbar--secondary .dropdown-menu.open {
  display: block;
  position: absolute;
  font-size: 1.4rem; }
  .navbar--secondary .dropdown-menu.open li {
    display: block;
    margin-left: 0;
    padding: 0.4rem 0; }
    .navbar--secondary .dropdown-menu.open li a:active {
      background-color: #dcdcdc;
      color: inherit; }

.site-footer {
  font-size: 1.2rem;
  margin-top: 7.5rem; }
  .site-footer .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px solid #ECECEC; }
  .site-footer * {
    font-size: inherit; }
  .site-footer p {
    display: inline-block;
    margin-bottom: 0; }
  .site-footer__siteby {
    display: flex;
    justify-content: flex-end; }
  .site-footer .youtube-icon {
    font-size: 2.7rem;
    display: inline-block;
    position: relative;
    top: -6px;
    margin-right: 10px;
    color: red; }
    .site-footer .youtube-icon svg {
      height: 1em;
      width: 1em; }
    .site-footer .youtube-icon span {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      top: -9px;
      margin-left: 4px;
      color: black; }
    .site-footer .youtube-icon:hover span {
      color: red; }
    @media (max-width: 1123.98px) {
      .site-footer .youtube-icon span {
        display: none; } }
  @media (max-width: 767.98px) {
    .site-footer .menu-footer-menu-container a {
      margin-right: 1rem; }
      .site-footer .menu-footer-menu-container a:last-of-type {
        margin-left: 0; }
    .site-footer .menu-footer-menu-container .menu-item {
      display: block; }
      .site-footer .menu-footer-menu-container .menu-item a {
        padding: 1rem 0;
        display: block; }
    .site-footer p {
      margin-bottom: 1.5rem; }
    .site-footer__siteby {
      justify-content: flex-start;
      margin-top: 1.5rem; }
    .site-footer * {
      font-size: 1.8rem; } }

.overflow-section {
  overflow: hidden; }

/* 
top-left : Top left
top-center : Top center
top-right : Top right
center-left : Centre left
center : Centre
center-right : Centre right
bottom-left : Bottom left
bottom-center : Bottom Centre
bottom-right : Bottom right
*/
.image-circle {
  position: relative; }
  .image-circle .circle {
    position: absolute; }
    .image-circle .circle svg {
      width: 100%;
      height: auto; }
  .image-circle--top-left .circle {
    top: 2rem;
    left: 2rem; }
  .image-circle--top-center .circle {
    top: 2rem;
    left: 50%;
    transform: translate(-50%, 0); }
  .image-circle--top-right .circle {
    top: 2rem;
    right: 2rem; }
  .image-circle--center-left .circle {
    top: 50%;
    left: 2rem;
    transform: translate(0, -50%); }
  .image-circle--center .circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .image-circle--center-right .circle {
    top: 50%;
    right: 2rem;
    transform: translate(0, -50%); }
  .image-circle--bottom-left .circle {
    bottom: 2rem;
    left: 2rem; }
  .image-circle--bottom-center .circle {
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0); }
  .image-circle--bottom-right .circle {
    bottom: 2rem;
    right: 2rem; }
  .image-circle--circle-big .circle {
    height: 45rem;
    width: 45rem; }
  .image-circle--circle-small .circle {
    height: 28rem;
    width: 28rem; }
  .image-circle--has-caption {
    margin-bottom: 4.8rem; }
    .image-circle--has-caption.image-circle--center .circle {
      top: calc(50% - 24px); }
  .image-circle img {
    width: 100%;
    height: 470px;
    object-fit: cover; }
  .image-circle.column-margin-top-first {
    margin-bottom: 8rem; }
  @media (max-width: 767.98px) {
    .image-circle img {
      height: auto !important; } }
  .image-circle--NONE svg {
    display: none !important; }

.image-circle--circle-big.image-circle--top-center,
.image-circle--circle-big.image-circle--top-right,
.image-circle--circle-big.image-circle--top-left {
  padding-top: 6.5rem; }
  .image-circle--circle-big.image-circle--top-center .circle,
  .image-circle--circle-big.image-circle--top-right .circle,
  .image-circle--circle-big.image-circle--top-left .circle {
    top: 0; }

.image-circle--circle-big.image-circle--bottom-center,
.image-circle--circle-big.image-circle--bottom-right,
.image-circle--circle-big.image-circle--bottom-left {
  padding-bottom: 6.5rem; }
  .image-circle--circle-big.image-circle--bottom-center .circle,
  .image-circle--circle-big.image-circle--bottom-right .circle,
  .image-circle--circle-big.image-circle--bottom-left .circle {
    bottom: 0; }

html[data-useragent*="rv:11.0"] .image-circle {
  height: 470px; }

.circle[data-aos="animate-circle"] path {
  fill: transparent;
  stroke: #128484;
  stroke-width: 8px;
  opacity: 0;
  stroke-dasharray: 1420;
  stroke-dashoffset: 0; }

.circle[data-aos="animate-circle"].aos-animate path {
  animation: animate-circle 1s ease-in-out alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

@keyframes animate-circle {
  from {
    stroke-dasharray: 0, 1420;
    opacity: 1; }
  to {
    stroke-dasharray: 1420, 1420;
    opacity: 1; } }

html[data-useragent*="rv:11.0"] .circle[data-aos="animate-circle"].aos-animate path {
  animation: animate-circle 1s ease-in-out alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
  stroke-dasharray: 1420, 1420 !important;
  opacity: 1 !important; }

.single-opportunities .circle-animation__circle {
  position: relative; }
  .single-opportunities .circle-animation__circle:before {
    content: attr(data-circle-index);
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    height: 6.3rem;
    width: 6.3rem;
    padding: 1.6rem 2rem;
    border: 5px solid #AB5F77;
    border-radius: 50%;
    background-color: white;
    z-index: 2;
    font-weight: bold;
    font-size: 22px;
    color: #AB5F77; }
    @media (max-width: 1123.98px) {
      .single-opportunities .circle-animation__circle:before {
        left: 1.5rem; } }

@media (min-width: 768px) and (max-width: 1124px) {
  .animated-circles .circle-animation {
    display: inline-grid; }
  .single-opportunities .animated-circles {
    text-align: center; } }

@media (max-width: 767.98px) {
  .animated-circles {
    max-width: 421px;
    overflow: hidden; } }

.community-opps-slider__content h2 {
  margin-bottom: 0; }

.community-opps-slider__content p {
  margin-top: 2rem;
  margin-bottom: 0; }

.community-opps-slider__content .link-group {
  margin-bottom: 1.8rem;
  font-size: 1.3rem;
  font-weight: 600; }
  .community-opps-slider__content .link-group--controls {
    margin-top: 2.4rem; }
    .community-opps-slider__content .link-group--controls .btn, .community-opps-slider__content .link-group--controls .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .community-opps-slider__content .link-group--controls input[type='submit'] {
      margin-right: 2rem; }

@media (max-width: 1439.98px) {
  .community-opps-slider .image-circle--circle-big .circle {
    height: 30rem;
    width: 30rem; }
    .community-opps-slider .image-circle--circle-big .circle[data-aos=animate-circle] path {
      stroke-width: 13px; } }

@media (max-width: 1123.98px) {
  .community-opps-slider .image-circle {
    margin-bottom: 3rem; } }

.overflow-section {
  margin-top: -11rem;
  margin-bottom: 5rem; }
  .overflow-section .tabs {
    padding-top: 16rem; }
    .overflow-section .tabs__mobile-content {
      display: none;
      text-align: center; }
      @media (min-width: 769px) {
        .overflow-section .tabs__mobile-content {
          display: none !important; } }
  @media (max-width: 1439.98px) {
    .overflow-section {
      margin-top: -5rem; } }
  @media (max-width: 1123.98px) {
    .overflow-section {
      margin-top: 5rem; }
      .overflow-section .tabs {
        padding-top: 0;
        margin-bottom: 5rem; } }
  @media (max-width: 769px) {
    .overflow-section .tabs {
      display: block; }
      .overflow-section .tabs .nav-tabs {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        display: block; }
      .overflow-section .tabs .nav-item {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem; }
        .overflow-section .tabs .nav-item .nav-link {
          font-weight: 400; }
      .overflow-section .tabs .tab-content {
        display: none; } }

.tabs {
  display: flex; }
  .tabs h4 {
    font-size: 2rem;
    margin-bottom: 2rem;
    line-height: 1.2;
    font-weight: 600; }
  .tabs .tab-content {
    display: flex;
    align-items: center;
    max-width: calc(100% - 31.5rem);
    flex-basis: calc(100% - 31.5rem); }
  .tabs .nav-tabs {
    border-bottom: none;
    padding-right: 6.5rem;
    max-width: 31.5rem;
    flex-basis: 31.5rem; }
  .tabs .nav-item {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    min-height: 135px;
    min-width: 250px;
    max-width: 250px;
    position: relative; }
    .tabs .nav-item.active .nav-link {
      background-color: transparent; }
      .tabs .nav-item.active .nav-link:before {
        content: "";
        background: url("../assets/images/tabs-active-bg.png") no-repeat center center transparent;
        background-size: 135px;
        width: 135px;
        height: 135px;
        position: absolute;
        z-index: -1;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
      .tabs .nav-item.active .nav-link span {
        color: #128484; }
  .tabs .nav-link {
    max-width: 100%;
    line-height: 1.1;
    text-align: center;
    border: none;
    color: #616161;
    font-size: calc(1.9rem + 0.9 * ( (100vw - 540px) / 420)); }
    @media screen and (max-width: 540px) {
      .tabs .nav-link {
        font-size: 1.9rem; } }
    @media screen and (min-width: 960px) {
      .tabs .nav-link {
        font-size: 2.8rem; } }
  @media (min-width: 1124px) {
    .tabs .nav-tabs {
      padding-right: 2.5rem;
      max-width: 27.5rem; }
    .tabs .nav-link {
      font-size: 2rem; }
    .tabs .nav-item {
      min-width: 209px;
      max-width: 209px; } }

.text-block h2 {
  max-width: 42rem;
  margin-bottom: 0; }

.text-block h3 {
  margin-bottom: 0; }

.text-block p {
  margin-bottom: 0; }
  .text-block p strong {
    font-weight: 400;
    font-size: 1.8rem; }

.text-block > * + * {
  margin-top: 3rem; }

.text-block ol {
  column-count: 2; }

.text-block--narrow {
  max-width: 52ch; }
  @media (max-width: 1123.98px) {
    .text-block--narrow {
      max-width: 100%; } }

html[data-useragent*="rv:11.0"] .text-block--narrow {
  max-width: 100%; }

.related-content-highlight {
  margin-bottom: 11rem; }
  @media (max-width: 1123.98px) {
    .related-content-highlight {
      margin-bottom: 7rem; }
      .related-content-highlight figure {
        margin-bottom: 0; }
      .related-content-highlight .wp-caption-text {
        display: none; } }
  @media (max-width: 767.98px) {
    .related-content-highlight .image-circle {
      overflow: hidden; } }

html[data-useragent*="rv:11.0"] .related-content-highlight {
  margin-bottom: 20rem; }

html[data-useragent*="rv:11.0"] div > svg.animate-drawing-svg {
  min-height: 300px; }

.circle-animation {
  display: grid;
  grid-template-columns: 270px 20px 270px;
  grid-template-rows: repeat(5, auto);
  margin-top: -75px; }
  .circle-animation__circle {
    width: 280px;
    height: 280px;
    border: 6px solid var(--scheme-clr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 2.4rem;
    margin-bottom: 4rem;
    opacity: 0;
    transition-property: opacity,-webkit-transform;
    transition-property: opacity,transform;
    transition-property: opacity,transform,-webkit-transform;
    transition-timing-function: ease;
    transition-duration: .5s; }
    .circle-animation__circle:nth-of-type(1) {
      grid-column: 2 / 4;
      grid-row: 1 / 3;
      transition-delay: .5s; }
    .circle-animation__circle:nth-of-type(2) {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
      transition-delay: 1s; }
    .circle-animation__circle:nth-of-type(3) {
      grid-column: 2 / 4;
      grid-row: 3 / 5;
      transition-delay: 1.5s; }
    .circle-animation__circle:nth-of-type(4) {
      grid-column: 1 / 3;
      grid-row: 4 / 6;
      transition-delay: 2s; }
    .circle-animation__circle p:last-of-type {
      margin-bottom: 0; }
    .circle-animation__circle:nth-of-type(odd) {
      transform: translate3d(100px, 0, 0); }
    .circle-animation__circle:nth-of-type(even) {
      transform: translate3d(-100px, 0, 0); }
  .circle-animation__title {
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .8rem;
    color: var(--scheme-clr); }
  .circle-animation__title-alt {
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: .8rem;
    color: var(--scheme-clr); }
    .circle-animation__title-alt a {
      text-decoration: underline; }
    @media (max-width: 1123.98px) {
      .circle-animation__title-alt {
        font-size: 2.4rem; } }
  .circle-animation--animated .circle-animation__circle {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  @media (min-width: 768px) and (max-width: 1439.98px) {
    .circle-animation {
      grid-template-columns: 236px 20px 236px; }
      .circle-animation__circle {
        width: 260px;
        height: 260px; }
        .circle-animation__circle p {
          font-size: 1.5rem; }
      .circle-animation__title {
        font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .circle-animation {
      display: block;
      margin-top: 4rem; }
      .circle-animation__circle {
        margin-left: auto;
        margin-right: auto; } }

html[data-useragent*="rv:11.0"] .circle-animation {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

html[data-useragent*="rv:11.0"] .circle-animation__circle {
  align-items: stretch;
  width: 26rem;
  height: 26rem;
  margin-left: 5px;
  margin-right: 5px;
  border: 6px solid #128484; }
  html[data-useragent*="rv:11.0"] .circle-animation__circle p {
    font-size: 1.5rem; }

html[data-useragent*="rv:11.0"] .circle-animation__icon {
  display: none; }

html[data-useragent*="rv:11.0"] .scheme--pink .circle-animation__circle {
  border: 6px solid #AB5F77; }

html[data-useragent*="rv:11.0"] .circle-animation__title {
  font-size: 1.7rem; }

.main-text-block > h4:first-child {
  margin-top: 0; }

.main-text-block a:not(.btn):not(.searchandfilter .sf-field-submit input[type='submit']),
.main-text-block a:not(.btn--link):not(.post-preview__meta a):not(.searchandfilter .sf-field-reset input[type='submit']) {
  text-decoration: underline; }
  .main-text-block a:not(.btn):not(.searchandfilter .sf-field-submit input[type='submit']):hover,
  .main-text-block a:not(.btn--link):not(.post-preview__meta a):not(.searchandfilter .sf-field-reset input[type='submit']):hover {
    text-decoration: underline solid #128484; }

.additional-content-block {
  margin-bottom: 12rem; }
  .additional-content-block__posts {
    padding: 40px 0 0 40px; }
    .additional-content-block__posts:before {
      content: "";
      height: 240px;
      width: calc(100% - 40px);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: block;
      background-color: #AB5F77; }
  @media (min-width: 768px) {
    .additional-content-block__twitter {
      padding-left: 5rem; } }
  .additional-content-block__twitter > img {
    margin-top: -1rem;
    margin-bottom: 2rem; }
  .additional-content-block__twitter #ctf .ctf-item {
    padding: 0; }
  .additional-content-block__twitter #ctf .ctf-tweet-text {
    color: #BE5814;
    font-size: calc(1.8rem + 1 * ( (100vw - 540px) / 420));
    font-weight: 300;
    margin-bottom: 2rem !important;
    line-height: 1.1; }
    @media screen and (max-width: 540px) {
      .additional-content-block__twitter #ctf .ctf-tweet-text {
        font-size: 1.8rem; } }
    @media screen and (min-width: 960px) {
      .additional-content-block__twitter #ctf .ctf-tweet-text {
        font-size: 2.8rem; } }
    .additional-content-block__twitter #ctf .ctf-tweet-text br + br {
      display: none; }
  .additional-content-block__twitter #ctf .ctf-tc-summary-info {
    color: #616161; }
  .additional-content-block__twitter #ctf .ctf-twitter-card .ctf-tc-url {
    opacity: 1;
    text-decoration: underline; }
  .additional-content-block__twitter #ctf .ctf-retweet-icon {
    background: #BE5814; }
  .additional-content-block__twitter .btn, .additional-content-block__twitter .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .additional-content-block__twitter input[type='submit'] {
    border-color: #BE5814;
    color: #BE5814; }
    .additional-content-block__twitter .btn:hover, .additional-content-block__twitter .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .additional-content-block__twitter input[type='submit']:hover {
      background-color: #BE5814;
      border-color: #BE5814;
      color: white; }
  @media (max-width: 1123.98px) {
    .additional-content-block {
      overflow: hidden; }
      .additional-content-block .post-preview {
        margin-bottom: 5rem; }
      .additional-content-block__posts {
        padding: 40px 40px 0 40px; }
        .additional-content-block__posts:before {
          width: 100vw; } }

.post-preview {
  position: relative;
  z-index: 2; }
  .post-preview h3 {
    font-size: calc(2.3rem + 0.5 * ( (100vw - 540px) / 420));
    font-family: "Nunito", sans-serif;
    line-height: 1.1;
    text-transform: none; }
    @media screen and (max-width: 540px) {
      .post-preview h3 {
        font-size: 2.3rem; } }
    @media screen and (min-width: 960px) {
      .post-preview h3 {
        font-size: 2.8rem; } }
  .post-preview h3, .post-preview p {
    margin-bottom: 0; }
  .post-preview > * + * {
    margin-top: 1.6rem; }
  .post-preview__image-container.objectfit {
    height: 240px; }
  .post-preview__meta {
    color: var(--scheme-clr);
    font-weight: 600;
    font-size: 1.3rem; }

html[data-useragent*="rv:11.0"] .post-preview__image-container.objectfit a {
  display: block;
  min-height: 100%; }

.grid-area .post-preview {
  padding: 40px 0 55px 40px; }
  .grid-area .post-preview:before {
    content: "";
    height: 24rem;
    width: calc(100% - 40px);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    opacity: .5; }
  .grid-area .post-preview__image-container.objectfit {
    height: 27rem;
    position: relative;
    z-index: 2;
    margin-bottom: 3.8rem; }
  .grid-area .post-preview__meta .btn--link, .grid-area .post-preview__meta a, .grid-area .post-preview__meta .searchandfilter .sf-field-reset input[type='submit'], .searchandfilter .sf-field-reset .grid-area .post-preview__meta input[type='submit'] {
    font-size: 1.3rem !important; }
  @media (max-width: 1123.98px) {
    .grid-area .post-preview:before {
      height: 20rem; }
    .grid-area .post-preview__image-container.objectfit {
      height: 20rem; } }

.grid-area__item:nth-of-type(3n+1) .post-preview:before {
  background-color: #128484; }

.grid-area__item:nth-of-type(3n+2) .post-preview:before {
  background-color: #BE5814; }

.grid-area__item:nth-of-type(3n+3) .post-preview:before {
  background-color: #AB5F77; }

.grid-area__item .btn--link, .grid-area__item .post-preview__meta a, .post-preview__meta .grid-area__item a, .grid-area__item .searchandfilter .sf-field-reset input[type='submit'], .searchandfilter .sf-field-reset .grid-area__item input[type='submit'] {
  font-size: 1.2rem; }

.grid-area__highlight .inner {
  padding: 3.5rem; }

.grid-area__highlight .objectfit {
  height: 29.5rem; }

.grid-area__highlight .btn, .grid-area__highlight .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .grid-area__highlight input[type='submit'] {
  border-color: white; }
  .grid-area__highlight .btn:hover, .grid-area__highlight .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .grid-area__highlight input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: #616161; }

.grid-area--standard .post-preview {
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0; }
  .grid-area--standard .post-preview h4 {
    line-height: 1.1; }

.grid-area--standard .post-preview__image-container.objectfit {
  height: 21rem;
  margin-bottom: 2.8rem; }

@media (max-width: 1123.98px) {
  .grid-area--standard .post-preview {
    margin-bottom: 5.5rem; } }

.big-cta-block {
  margin-top: 2rem; }
  .big-cta-block .container {
    padding-top: 8rem;
    border-top: 1px solid #ECECEC; }
  .big-cta-block p {
    margin-bottom: 3rem; }

.pagination .page-numbers {
  padding: 3px 8px;
  border: 1px solid #616161;
  display: inline-block;
  text-align: center;
  margin-right: .8rem;
  font-size: 12px;
  font-weight: 400; }
  .pagination .page-numbers:hover {
    background-color: rgba(18, 132, 132, 0.1);
    text-decoration: none; }
  .pagination .page-numbers.current {
    border-color: #128484;
    background-color: #128484;
    color: white; }
    .pagination .page-numbers.current:hover {
      background-color: #128484; }

.alignright {
  float: right;
  padding: 0px 0px 10px 0px; }

.alignleft {
  float: left;
  padding: 0px 10px 0px 0px; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px; }

.alignnone {
  padding: 10px 0; }

#content .hero {
  overflow: hidden; }
  #content .hero h1 {
    margin-bottom: 4rem; }
    @media (max-width: 1123.98px) {
      #content .hero h1 {
        font-size: 3.9rem; } }
    @media (max-width: 767.98px) {
      #content .hero h1 {
        font-size: 3.4rem; } }
  #content .hero strong, #content .hero b {
    font-weight: 700; }
  #content .hero p {
    max-width: 100%; }
  #content .hero .btn, #content .hero .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit #content .hero input[type='submit'] {
    border-color: #3D3D3D;
    color: #3D3D3D;
    margin-top: 2rem; }
    #content .hero .btn:hover, #content .hero .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit #content .hero input[type='submit']:hover {
      background: #3D3D3D;
      color: white; }
  #content .hero__content {
    position: relative;
    width: calc(100% + 10rem);
    left: -2rem; }
    #content .hero__content .aos-init p:last-of-type {
      margin-bottom: 0; }
    @media (max-width: 1123.98px) {
      #content .hero__content {
        left: 0;
        width: 100%; } }
  #content .hero__top .hero__content {
    padding: 8.2rem 10rem; }
  @media (max-width: 1123.98px) {
    #content .hero__top {
      flex-direction: column-reverse !important; }
      #content .hero__top .hero__content {
        padding: 7rem; } }
  @media (max-width: 767.98px) {
    #content .hero__top .hero__content {
      padding: 5rem 3rem;
      min-height: 0;
      z-index: 5; } }
  #content .hero__bottom .hero__content {
    padding: 5rem 10rem; }
    #content .hero__bottom .hero__content p:last-of-type {
      margin-bottom: 0; }
    @media (max-width: 1123.98px) {
      #content .hero__bottom .hero__content {
        margin-bottom: 4rem; } }
  #content .hero__bottom .hero__bottom__slider-container {
    margin: -4rem 0 4rem 0; }
    @media (max-width: 1123.98px) {
      #content .hero__bottom .hero__bottom__slider-container {
        margin: 0; } }
  #content .hero__image {
    position: relative;
    width: calc(100% + 12rem);
    left: -8rem; }
    #content .hero__image img {
      height: 47rem; }
    @media (max-width: 1123.98px) {
      #content .hero__image {
        left: 0;
        width: 100%; } }
  #content .hero__slider {
    margin-top: 2rem; }
    #content .hero__slider .slider__slide p {
      margin-bottom: 0; }
    #content .hero__slider .slider__slide > * + * {
      margin-top: 2.2rem; }
  #content .hero--default {
    position: relative;
    overflow: visible;
    margin: 4.5rem 0 8.5rem; }
    #content .hero--default .container {
      padding-top: 7rem;
      padding-bottom: 7rem; }
      #content .hero--default .container:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 50vw;
        display: block;
        background: inherit; }
      #content .hero--default .container:before {
        content: "";
        position: absolute;
        margin-left: -8rem;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 50vw;
        display: block;
        background: inherit; }
    #content .hero--default h1 {
      margin-bottom: 0; }
  #content .hero--reversed {
    margin-bottom: 5rem; }
    #content .hero--reversed .hero__content {
      left: -8rem;
      margin-top: 5rem;
      margin-bottom: 5rem;
      min-height: 0;
      padding: 11.2rem 7rem 11.2rem 8rem; }
      #content .hero--reversed .hero__content .text-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end; }
      #content .hero--reversed .hero__content .btn, #content .hero--reversed .hero__content .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit #content .hero--reversed .hero__content input[type='submit'] {
        text-align: center; }
      #content .hero--reversed .hero__content h1 {
        margin-bottom: 0; }
      #content .hero--reversed .hero__content p {
        margin-top: 1.5rem; }
        #content .hero--reversed .hero__content p:last-of-type {
          margin-bottom: 0; }
      @media (min-width: 768px) and (max-width: 1439.98px) {
        #content .hero--reversed .hero__content h1 {
          font-size: 3.9rem; } }
    #content .hero--reversed .hero__image {
      width: calc(100% + 3rem);
      left: -3rem; }
    @media (max-width: 1123.98px) {
      #content .hero--reversed {
        margin-top: 2rem; }
        #content .hero--reversed .hero__content,
        #content .hero--reversed .hero__image {
          left: 0;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0; }
        #content .hero--reversed .hero__content {
          padding: 7rem; }
          #content .hero--reversed .hero__content h1 {
            margin-bottom: 2rem; }
          #content .hero--reversed .hero__content .text-right {
            text-align: left !important;
            display: block; }
        #content .hero--reversed .image-circle--circle-big.image-circle--bottom-center {
          padding-bottom: 0; }
        #content .hero--reversed figure {
          margin-bottom: 0; }
        #content .hero--reversed .wp-caption-text {
          display: none; } }
    @media (max-width: 767.98px) {
      #content .hero--reversed .hero__content {
        padding: 5rem 3rem; }
      #content .hero--reversed .image-circle--circle-big .circle {
        height: 30rem;
        width: 30rem; }
        #content .hero--reversed .image-circle--circle-big .circle[data-aos=animate-circle] path {
          stroke-width: 13px; } }
  #content .hero--contained .hero__content {
    left: 0;
    width: 100%; }
  #content .hero--contained .hero__image {
    left: 0;
    width: 100%; }
  #content .hero--home {
    margin-bottom: 7rem; }
    #content .hero--home h1 {
      font-size: 5.6rem;
      margin-bottom: 0; }
    #content .hero--home a:not(.btn):not(.searchandfilter .sf-field-submit input[type='submit']) {
      text-decoration: underline; }
    #content .hero--home .hero__image {
      margin-top: 2rem; }
    #content .hero--home .hero__top .hero__content {
      min-height: 65rem; }
      #content .hero--home .hero__top .hero__content p:last-of-type {
        margin-bottom: 3px; }
    #content .hero--home .hero__top .image-circle img {
      height: 48rem; }
    #content .hero--home .hero__bottom .link-group {
      margin-top: 3rem; }
    @media (max-width: 1439.98px) {
      #content .hero--home h1 {
        margin-bottom: 4rem;
        font-size: 4.6rem; }
      #content .hero--home .hero__top .hero__content {
        padding: 9.2rem 10rem; }
      #content .hero--home .ul--no-style {
        margin-top: 3rem; } }
    @media (max-width: 1123.98px) {
      #content .hero--home {
        margin-bottom: 2rem; } }
    @media (max-width: 767.98px) {
      #content .hero--home .hero__top .hero__content {
        padding: 5rem 3rem 5rem;
        min-height: 0; }
        #content .hero--home .hero__top .hero__content--has-notice {
          padding: 10rem 3rem 5rem; }
      #content .hero--home .hero__top .image-circle img {
        height: 34rem; }
      #content .hero--home .hero__top .image-circle .circle {
        height: 35rem;
        width: 35rem; }
      #content .hero--home h1 {
        font-size: 3.6rem; }
      #content .hero--home .hero__bottom .hero__content {
        padding: 5rem 3rem; } }
  #content .hero--contact .hero__content {
    padding: 9rem; }
    #content .hero--contact .hero__content h1 {
      margin-bottom: 3.5rem; }
    #content .hero--contact .hero__content p {
      margin-bottom: 3rem; }
    #content .hero--contact .hero__content li {
      display: flex;
      flex-direction: row;
      align-items: baseline; }
    #content .hero--contact .hero__content img {
      position: relative;
      top: 3px; }
    #content .hero--contact .hero__content a {
      font-size: 2.4rem;
      font-weight: 600;
      padding-left: 1.8rem;
      vertical-align: middle;
      display: inline-block; }
    #content .hero--contact .hero__content ul {
      margin-top: 3rem; }
    @media (max-width: 1123.98px) {
      #content .hero--contact .hero__content {
        padding: 5rem 3rem; } }
    @media (max-width: 767.98px) {
      #content .hero--contact .hero__content a[href^="mailto"] {
        word-break: break-all; } }
  #content .hero--general-text {
    margin-bottom: 6rem; }
  #content .hero .slider-arrow {
    display: none; }
  @media (max-width: 1123.98px) {
    #content .hero {
      margin-bottom: 8rem; } }
  #content .hero--blog {
    margin-bottom: 6.5rem; }
  #content .hero .ul--no-style .btn, #content .hero .ul--no-style .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit #content .hero .ul--no-style input[type='submit'] {
    margin-top: 0; }
  #content .hero .notice {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    padding-left: 10.2rem;
    width: 100%;
    background: #AB5F77;
    color: #ffffff; }

@media (min-width: 768px) {
  .single-stories .hero--reversed h1 {
    font-size: 3.6rem; } }

@media (max-width: 1123.98px) {
  .single-stories .hero--reversed {
    margin-bottom: 5rem; } }

@media (max-width: 767.98px) {
  #content .hero.hero--default {
    margin-top: 2rem;
    margin-bottom: 6rem; }
    #content .hero.hero--default .container {
      padding-top: 4rem;
      padding-bottom: 3.5rem; } }

html[data-useragent*="rv:11.0"] .hero .hero__image.objectfit {
  margin-top: 6rem !important; }

.content-404 h1 {
  font-size: calc(6.5rem + 4 * ( (100vw - 540px) / 420));
  margin-bottom: .1em; }
  @media screen and (max-width: 540px) {
    .content-404 h1 {
      font-size: 6.5rem; } }
  @media screen and (min-width: 960px) {
    .content-404 h1 {
      font-size: 10.5rem; } }

.content-404 h2 {
  margin-bottom: 2rem; }

.content-404 p {
  margin-bottom: 3rem; }

.content-404 .btn, .content-404 .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .content-404 input[type='submit'] {
  color: white !important;
  border-color: white !important; }
  .content-404 .btn:hover, .content-404 .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .content-404 input[type='submit']:hover {
    border-color: white !important;
    background-color: white !important;
    color: #AB5F77 !important; }

html {
  font-size: 62.5%; }

body {
  color: #616161;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  overflow-anchor: auto; }

p {
  font-size: calc(1.5rem + 0.1 * ( (100vw - 540px) / 420));
  margin-bottom: 1.7rem;
  line-height: 1.5; }
  @media screen and (max-width: 540px) {
    p {
      font-size: 1.5rem; } }
  @media screen and (min-width: 960px) {
    p {
      font-size: 1.6rem; } }
  p strong, p b {
    font-weight: 700; }

a {
  color: inherit; }
  a:hover {
    color: inherit; }

.btn, .searchandfilter .sf-field-submit input[type='submit'],
.menu-btn a,
.frm_button_submit {
  display: inline-block;
  padding: 11px 23px;
  border: 2px solid #616161;
  border-radius: 24px;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .5px;
  transition: all .2s ease-in-out; }
  .btn:hover, .searchandfilter .sf-field-submit input[type='submit']:hover,
  .menu-btn a:hover,
  .frm_button_submit:hover {
    text-decoration: none;
    background-color: #616161;
    background-color: var(--scheme-clr);
    border-color: var(--scheme-clr);
    color: white; }
  .btn--link, .post-preview__meta a, .searchandfilter .sf-field-reset input[type='submit'],
  .menu-btn a--link,
  .frm_button_submit--link {
    border: none;
    display: inline-block;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 1.3rem;
    text-decoration: underline;
    color: var(--scheme-clr);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .5px; }
    .btn--link:hover, .post-preview__meta a:hover, .searchandfilter .sf-field-reset input[type='submit']:hover,
    .menu-btn a--link:hover,
    .frm_button_submit--link:hover {
      text-decoration: none;
      color: inherit; }
    .btn--link .inline-open-arrow, .post-preview__meta a .inline-open-arrow, .searchandfilter .sf-field-reset input[type='submit'] .inline-open-arrow,
    .menu-btn a--link .inline-open-arrow,
    .frm_button_submit--link .inline-open-arrow {
      height: 12px;
      margin-left: 10px; }
  .btn--link--small, .post-preview__meta a,
  .menu-btn a--link--small,
  .frm_button_submit--link--small {
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 600 !important; }
  .btn--fill,
  .menu-btn a--fill,
  .frm_button_submit--fill {
    background-color: #616161;
    background-color: var(--scheme-clr);
    border-color: var(--scheme-clr);
    color: white; }
    .btn--fill:hover,
    .menu-btn a--fill:hover,
    .frm_button_submit--fill:hover {
      background-color: white;
      border-color: #616161;
      color: #616161; }

.btn[download], .searchandfilter .sf-field-submit input[download][type='submit'] {
  position: relative; }
  .btn[download]:after, .searchandfilter .sf-field-submit input[download][type='submit']:after {
    content: "";
    background: url("../assets/images/download-black.png") no-repeat center;
    background-size: 100%;
    display: inline-block;
    height: 10px;
    width: 9px;
    margin-left: 8px;
    position: relative;
    top: 1px;
    transition: all .2s ease-in-out; }
  .btn[download]:hover, .searchandfilter .sf-field-submit input[download][type='submit']:hover {
    color: white; }
    .btn[download]:hover:after, .searchandfilter .sf-field-submit input[download][type='submit']:hover:after {
      content: "";
      background: url("../assets/images/download-white.png") no-repeat center;
      background-size: 100%; }

figure figcaption,
.wp-caption-text {
  font-size: 1.6rem;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  margin-top: 1.7rem; }

html[data-useragent*="rv:11.0"] figure figcaption,
html[data-useragent*="rv:11.0"] .wp-caption-text {
  display: none; }

div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%; }

html[data-useragent*="rv:11.0"] div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%; }

html[data-useragent*="rv:11.0"] .compat-object-fit {
  background-size: cover;
  background-position: center center; }
  html[data-useragent*="rv:11.0"] .compat-object-fit img {
    display: none !important; }

.skiplink {
  position: absolute;
  left: -9999em; }
  .skiplink:focus {
    position: relative;
    left: 0; }

.main-text-block .iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .main-text-block .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.bg-blue, .bg-teal {
  background-color: #128484;
  color: white; }

.bg-light-blue, .bg-light-teal {
  background-color: rgba(18, 132, 132, 0.2); }
  .bg-light-blue .btn, .bg-light-blue .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-blue input[type='submit'], .bg-light-teal .btn, .bg-light-teal .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-teal input[type='submit'] {
    border-color: #616161; }
    .bg-light-blue .btn:hover, .bg-light-blue .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .bg-light-blue input[type='submit']:hover, .bg-light-teal .btn:hover, .bg-light-teal .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .bg-light-teal input[type='submit']:hover {
      background-color: #616161;
      border-color: #616161; }

.bg-pink, .bg-fuchsia {
  background-color: #AB5F77;
  color: white; }

.bg-light-pink, .bg-light-fuchsia {
  background-color: rgba(171, 95, 119, 0.2); }
  .bg-light-pink .btn, .bg-light-pink .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-pink input[type='submit'], .bg-light-fuchsia .btn, .bg-light-fuchsia .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-fuchsia input[type='submit'] {
    border-color: inherit; }

.bg-orange, .bg-peach {
  background-color: #E8A87C;
  color: #3D3D3D; }
  .bg-orange h1, .bg-peach h1 {
    color: #060606; }

.bg-light-orange, .bg-light-peach {
  background-color: rgba(190, 88, 20, 0.2);
  color: #3D3D3D; }
  .bg-light-orange .btn, .bg-light-orange .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-orange input[type='submit'], .bg-light-peach .btn, .bg-light-peach .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .bg-light-peach input[type='submit'] {
    border-color: inherit; }
    .bg-light-orange .btn:hover, .bg-light-orange .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .bg-light-orange input[type='submit']:hover, .bg-light-peach .btn:hover, .bg-light-peach .searchandfilter .sf-field-submit input[type='submit']:hover, .searchandfilter .sf-field-submit .bg-light-peach input[type='submit']:hover {
      background-color: #616161;
      border-color: #616161; }
  .stories-slider__slide .bg-light-orange,
  .single-opportunities .related-content-highlight .bg-light-orange, .stories-slider__slide .bg-light-peach,
  .single-opportunities .related-content-highlight .bg-light-peach {
    background: white;
    border: 8px solid #E9B592;
    border-right: none; }
    @media (max-width: 1124px) {
      .stories-slider__slide .bg-light-orange,
      .single-opportunities .related-content-highlight .bg-light-orange, .stories-slider__slide .bg-light-peach,
      .single-opportunities .related-content-highlight .bg-light-peach {
        border-right: 8px solid #E9B592; } }
    .stories-slider__slide .bg-light-orange .btn, .stories-slider__slide .bg-light-orange .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .stories-slider__slide .bg-light-orange input[type='submit'],
    .single-opportunities .related-content-highlight .bg-light-orange .btn,
    .single-opportunities .related-content-highlight .bg-light-orange .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit
    .single-opportunities .related-content-highlight .bg-light-orange input[type='submit'], .stories-slider__slide .bg-light-peach .btn, .stories-slider__slide .bg-light-peach .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit .stories-slider__slide .bg-light-peach input[type='submit'],
    .single-opportunities .related-content-highlight .bg-light-peach .btn,
    .single-opportunities .related-content-highlight .bg-light-peach .searchandfilter .sf-field-submit input[type='submit'], .searchandfilter .sf-field-submit
    .single-opportunities .related-content-highlight .bg-light-peach input[type='submit'] {
      border-color: #616161; }
  .single-opportunities .related-content-highlight .bg-light-orange, .single-opportunities .related-content-highlight .bg-light-peach {
    border-right: 8px solid #E9B592;
    position: relative;
    margin-left: -8px; }
    @media (max-width: 1124px) {
      .single-opportunities .related-content-highlight .bg-light-orange, .single-opportunities .related-content-highlight .bg-light-peach {
        margin-left: 0; } }

.scheme--orange, .scheme--peach {
  --scheme-clr: #BE5814; }

.scheme--pink, .scheme--fuchsia {
  --scheme-clr: #AB5F77; }

.scheme--blue, .scheme--teal {
  --scheme-clr: #128484; }

.circle[data-aos="animate-circle"] path {
  stroke: var(--scheme-clr); }

.scheme--orange .circle[data-aos="animate-circle"] path,
.scheme--peach .circle[data-aos="animate-circle"] path {
  stroke: #E8A87C; }

h1 span,
h2 span,
h3 span {
  color: var(--scheme-clr); }

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
  width: 100%;
  min-width: 100px;
  height: 44px;
  font-weight: 700;
  font-size: 12px;
  border: 0;
  padding: 0 18px; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.grid-area select {
  padding: 11px 40px 11px 23px;
  border: 2px solid #128484;
  border-radius: 24px;
  color: #128484;
  text-transform: uppercase;
  letter-spacing: .5px;
  width: calc(100% + 5px); }
  @supports (display: grid) {
    .grid-area select {
      appearance: none;
      background: url("../assets/images/select-arrow.svg") no-repeat calc(100% - 20px) center;
      background-size: 9px; } }
  @supports (-ms-ime-align: auto) {
    .grid-area select {
      background: none !important;
      appearance: menulist !important;
      padding-right: 23px !important; } }
  .grid-area select option {
    font-size: 14px;
    color: #222;
    font-weight: normal; }

html[data-useragent*="rv:11.0"] .grid-area select {
  padding: 11px 23px; }

.frm_verify {
  display: none; }

.searchandfilter ul {
  display: flex;
  flex-wrap: wrap; }
  .searchandfilter ul > li + li {
    margin-left: 3rem; }

.searchandfilter input[type='text'] {
  padding: 11px 40px 11px 23px;
  border: 2px solid #128484;
  border-radius: 24px;
  font-size: 16px;
  font-weight: normal; }

.searchandfilter .sf-field-reset input[type='submit'] {
  background: transparent;
  cursor: pointer;
  height: 44px;
  color: #616161; }

.searchandfilter .sf-field-submit input[type='submit'] {
  background: transparent;
  cursor: pointer;
  height: 44px; }

@media (max-width: 767.98px) {
  .searchandfilter ul {
    flex-wrap: nowrap;
    flex-direction: column; }
    .searchandfilter ul > li + li {
      margin-left: 0; } }

::-webkit-input-placeholder {
  color: #555; }

::-moz-placeholder {
  color: #555; }

:-ms-input-placeholder {
  color: #555; }

:-moz-placeholder {
  color: #555; }

.main {
  flex: 1 0 auto; }

.content {
  position: relative; }

.m-t-20 {
  margin-top: 2rem; }

.m-t-40 {
  margin-top: 4rem; }

.m-b-40 {
  margin-bottom: 4rem; }

.m-b-60 {
  margin-bottom: 6rem; }

.m-r-20 {
  margin-right: 2rem; }

.column-margin-top-first {
  margin-top: 9rem; }
  @media (max-width: 1123.98px) {
    .column-margin-top-first {
      margin-top: 6rem; } }

.spacer {
  margin: 8rem 0;
  display: block;
  width: 100%;
  height: 1px;
  background: #ECECEC; }
  @media (max-width: 767.98px) {
    .spacer {
      margin: 5.5rem 0; } }

.custom-grid--align-bottom {
  align-items: flex-end; }

.custom-grid__col-55 {
  flex-basis: 55%;
  max-width: 55%; }

.custom-grid__col-50 {
  flex-basis: 50%;
  max-width: 50%; }

.custom-grid__col-45 {
  flex-basis: 45%;
  max-width: 45%; }

.custom-grid__col-40 {
  flex-basis: 40%;
  max-width: 40%; }

@media (max-width: 1123.98px) {
  .custom-grid__col-55 {
    flex-basis: 100%;
    max-width: 100%; }
  .custom-grid__col-50 {
    flex-basis: 100%;
    max-width: 100%; }
  .custom-grid__col-45 {
    flex-basis: 100%;
    max-width: 100%; }
  .custom-grid__col-40 {
    flex-basis: 100%;
    max-width: 100%; } }

.custom-grid .inner.block-padding a {
  text-decoration: underline; }
  .custom-grid .inner.block-padding a:hover {
    text-decoration: none; }

.block-padding {
  padding: 8rem 6.5rem; }
  @media (max-width: 1123.98px) {
    .block-padding {
      padding: 8rem 3rem 5rem; } }

.faux-col-padding-left {
  padding-left: 8rem; }
  @media (max-width: 1123.98px) {
    .faux-col-padding-left {
      padding-left: 15px; } }

#eu-cookie-bar {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px 15px;
  font-size: 1.6rem; }
  #eu-cookie-bar a {
    color: #E9B491; }
  #eu-cookie-bar button {
    height: 20px;
    width: 20px;
    background-image: url("../assets/images/cross-white.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 15px !important;
    background-color: transparent !important;
    padding: 0;
    font-size: 0;
    border: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-left: 2rem; }

p.larger {
  font-size: 2rem;
  line-height: 1.2; }

h1,
h2,
h3,
blockquote {
  font-family: 'pironregular';
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: normal; }

h1, .alpha {
  font-size: calc(3.9rem + 1 * ( (100vw - 540px) / 420));
  line-height: 1; }
  @media screen and (max-width: 540px) {
    h1, .alpha {
      font-size: 3.9rem; } }
  @media screen and (min-width: 960px) {
    h1, .alpha {
      font-size: 4.9rem; } }
  h1 span, .alpha span {
    color: #060606; }

h2, .beta {
  font-size: calc(3.5rem + 1.1 * ( (100vw - 540px) / 420));
  line-height: 1.1; }
  @media screen and (max-width: 540px) {
    h2, .beta {
      font-size: 3.5rem; } }
  @media screen and (min-width: 960px) {
    h2, .beta {
      font-size: 4.6rem; } }

h3, .gamma {
  font-size: calc(3.2rem + 0.4 * ( (100vw - 540px) / 420));
  line-height: 1.1;
  margin-bottom: .5em; }
  @media screen and (max-width: 540px) {
    h3, .gamma {
      font-size: 3.2rem; } }
  @media screen and (min-width: 960px) {
    h3, .gamma {
      font-size: 3.6rem; } }

h4, .delta {
  font-size: calc(2.3rem + 0.5 * ( (100vw - 540px) / 420));
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: .5em; }
  @media screen and (max-width: 540px) {
    h4, .delta {
      font-size: 2.3rem; } }
  @media screen and (min-width: 960px) {
    h4, .delta {
      font-size: 2.8rem; } }

h5, .epsilon {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .5px; }

blockquote {
  margin: 3.4rem 0;
  background-color: #AB5F77;
  position: relative;
  padding: 8.5rem 0 0 9.5rem; }
  blockquote:after {
    content: "";
    position: absolute;
    background-color: white;
    height: calc(100% - 4rem);
    width: calc(100% - 4rem);
    right: 0;
    bottom: 0; }
  blockquote * {
    position: relative;
    z-index: 1;
    font-size: 2.6rem;
    line-height: 1.2;
    color: #AB5F77; }

.ul--no-style {
  margin: 0;
  padding: 0;
  list-style: none; }
  .ul--no-style > * + * {
    margin-top: 2.2rem; }

.ul--phone-numbers {
  font-size: 2.8rem;
  font-weight: 400;
  margin-top: 2rem; }
  .ul--phone-numbers li {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .ul--phone-numbers li span {
      display: block;
      border-bottom: 2px dotted #616161;
      flex-grow: 2;
      margin: 0 14px 0 7px;
      position: relative;
      top: -11px; }
    .ul--phone-numbers li a {
      margin-left: auto;
      position: relative; }
      .ul--phone-numbers li a:before {
        content: "";
        background: url("../assets/images/phone.png") no-repeat center;
        background-size: 100%;
        display: inline-block;
        height: 23px;
        width: 21px;
        position: relative;
        top: 3px;
        left: -6px; }
  @media (max-width: 1123.98px) {
    .ul--phone-numbers {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .ul--phone-numbers li {
      display: block; }
      .ul--phone-numbers li span {
        display: none; } }

ol, ul {
  padding-left: 20px; }

.slider-block {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  color: #333; }
  .slider-block .slider__item {
    text-align: center;
    color: #000000;
    background: white; }

.stories-slider__slide {
  display: flex !important; }
  @media (max-width: 1123.98px) {
    .stories-slider__slide {
      flex-direction: column-reverse; } }

.stories-slider .slider-arrow {
  background-color: currentColor; }

.activities-slider .slick-track {
  min-width: 100% !important; }

.activities-slider__slide {
  padding: 0 15px; }

.slider__slide .btn--link, .slider__slide .post-preview__meta a, .post-preview__meta .slider__slide a, .slider__slide .searchandfilter .sf-field-reset input[type='submit'], .searchandfilter .sf-field-reset .slider__slide input[type='submit'] {
  color: #616161; }

.slider-arrow,
.slick-arrow {
  height: 38px;
  width: 38px;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background-color: #616161;
  background-color: var(--scheme-clr);
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 0;
  vertical-align: middle;
  color: inherit; }
  .slider-arrow#left-arrow, .slider-arrow.slick-prev, .slider-arrow.left-arrow,
  .slick-arrow#left-arrow,
  .slick-arrow.slick-prev,
  .slick-arrow.left-arrow {
    background-image: url("../assets/images/arrow-white-left.png"); }
  .slider-arrow#right-arrow, .slider-arrow.slick-next, .slider-arrow.right-arrow,
  .slick-arrow#right-arrow,
  .slick-arrow.slick-next,
  .slick-arrow.right-arrow {
    background-image: url("../assets/images/arrow-white-right.png");
    margin-left: .5rem; }
  .slider-arrow:hover,
  .slick-arrow:hover {
    opacity: .5; }

.image-slider {
  position: relative;
  padding-right: 1.8rem;
  overflow: hidden; }
  .image-slider figure {
    margin-bottom: 0; }
    .image-slider figure img {
      width: 100%;
      max-width: 100%;
      height: 640px;
      object-fit: cover; }
    .image-slider figure .lightbox {
      position: relative;
      display: block; }
      .image-slider figure .lightbox:after {
        content: "";
        background-image: url("../assets/images/plus-white.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #222;
        background-color: var(--scheme-clr);
        height: 38px;
        width: 38px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .image-slider figure .lightbox:hover:after {
        opacity: .5; }
  .image-slider .slick-list {
    overflow: visible; }
  .image-slider .slick-arrow {
    position: absolute;
    top: 30rem;
    right: 0;
    z-index: 10;
    background-color: #222;
    background-color: var(--scheme-clr); }
    .image-slider .slick-arrow.slick-prev {
      right: 50px; }
  @media (max-width: 767.98px) {
    .image-slider figure img {
      height: 400px; }
    .image-slider .slick-arrow {
      top: 41.1%; } }

html[data-useragent*="rv:11.0"] .image-slider figure {
  height: 640px; }
  html[data-useragent*="rv:11.0"] .image-slider figure a {
    display: block;
    min-height: 640px; }

@media (max-width: 1123.98px) {
  .single-post .image-slider {
    margin-top: 5rem; } }
