//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************

$menuBg: #3d3d3d;

.main-nav {
    .navbar {
        width: 100%;
        padding: 1rem 1.5rem;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
        }
        .navbar--primary {
            // MAIN NAVIGATION
            .menu-item {
                position: relative;

                // MAIN LINK
                .nav-link {
                    padding: 1.5rem 0.5rem;
                    margin: 0 1.7rem;
                    color: inherit;
                    font-size: 1.6rem;
                    font-weight: 400;
                    
                    @include fade-transition(0.3s);

                    &:after {
                        display: none;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 1.5rem 0rem;
                    }

                    &:hover {
                        color: $teal;
                    }
                }

                // SUB NAVIGATION TOGGLE
                .menu-dropdown-toggle {
                    display: none;
                }
                
                @include media-breakpoint-up(lg) {
                    // SUB NAVIGATION
                    .dropdown-menu {
                        padding: 3rem 5rem;
                        border: 0;
                        border-radius: 11px;
                        margin-top: 0;
                        background: $menuBg;
                        transition: opacity .3s ease-in-out;
                        opacity: 0;
                        display: block;
                        left: 50%;
                        top:-99999px;
                        transform: translate(-50%, 0);

                        &:before {
                            content: "";
                            width: 32px;
                            height: 33px;
                            background: $menuBg;
                            border-radius: 5px;
                            position: absolute;
                            top: -15px;
                            left: 49.4%;
                            transform: rotate(-43deg) skew(5deg) translate(-50%, 0);
                        }
                        // SUB LINK
                        .dropdown-item {
                            position: relative;
                            display: block;
                            width: 100%;
                            padding: 1.5rem 0;
                            color: white;
                            white-space: nowrap;
                            border: 0;
                            font-size: 1.8rem;
                            line-height: 1;
                            background-color: transparent;
                            @include fade-transition(0.3s);
                            
                            &:hover {
                                text-decoration: underline;
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                // DESKTOP
                @include media-breakpoint-up(lg) {
                    &:hover {
                        .nav-link {
                        }
                        .dropdown-menu {
                            display: block;
                            opacity: 1; 
                            position: absolute; 
                            //top: 126%; 
                            top: 98%; 
                        }
                    }
                }

                // MOBILE
                @include media-breakpoint-between(xs, md) {
                    &:hover {
                        .nav-link {
                            color: #000;
                        }
                        .menu-dropdown-toggle {
                            .menu-dropdown-toggle-icon {
                                &:before,
                                &:after {
                                    background: #fff;
                                }
                            }
                        }
                    }

                    // MAIN LINK
                    .nav-link {
                        padding: 0.2rem 0;
                    }

                    // SUB NAVIGATION
                    .dropdown-menu {
                        &.open {
                            position: relative;
                            display: block;
                            padding: 0;
                            background: transparent;
                        }
                        // SUB LINK
                        .dropdown-item {
                            padding: 0.5rem 0;
                            &:after {
                                right: 0;
                            }
                        }
                    }

                    // SUB NAVIGATION TOGGLE
                    .menu-dropdown-toggle {
                        padding: 1rem       ;
                        border: 0;
                        border-radius: 0;
                        position: absolute;
                        top: -0.2rem;
                        right: -1rem;
                        display: block;
                        background: transparent;
                        .menu-dropdown-toggle-icon {
                            position: relative;
                            cursor: pointer;
                            height: 3rem;
                            width: 2rem;
                            display: block;
                            &:before,
                            &:after {
                                background: #fff;
                                content: '';
                                display: block;
                                width: 2rem;
                                height: .3rem;
                                top: 1.35rem;
                                position: absolute;
                                @include fade-transition(0.3s);
                            }
                            &:before {
                                -webkit-transform: rotate(-180deg);
                                transform: rotate(-180deg);
                            }
                            &:after {
                                -webkit-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                        
                        &.open {
                            .menu-dropdown-toggle-icon {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            
            @include media-breakpoint-up(lg){
                > .menu-item {
                    &.current_page_item,
                    &.current-menu-parent,
                    &.current-menu-item {
                        position: relative;

                        &:before {
                            content: "";
                            height: 44px;
                            width: 44px;
                            border-radius: 50%;
                            display: block;
                            background-color: white;
                            border: 3px solid rgba($teal, 0.3);
                            position: absolute;
                            z-index: 0;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        .nav-link {
                            position: relative;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .navbar-collapse {
            @include media-breakpoint-down(md) {
                order: 3;
                color: white;
                position: relative;

                .navbar--primary {
                    background-color: $menuBg;
                    padding: 3rem 5rem;
                    border: 0;
                    border-radius: 11px;
                    position: relative;
                    margin-top: 3rem;

                    &:before {
                        content: "";
                        width: 32px;
                        height: 33px;
                        background: $menuBg;
                        border-radius: 5px;
                        position: absolute;
                        top: -15px;
                        left: 49.4%;
                        transform: rotate(-43deg) skew(5deg) translate(-50%, 0);
                    }   

                    .menu-item {
                        .nav-link {
                            font-size: 2rem;
                            padding: 1rem 0;
                            margin: 0;
                        }
                        &:hover {
                            .nav-link {
                                color: white;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    border: none;
                    font-size: inherit;
                    padding: 0 2rem 2rem !important;
                }

                .dropdown-item {
                    color: white;

                    &:hover {
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .navbar--primary {
                    padding: 2rem 2rem;
                }
            }
        }
    }
}

// MAIN NAVIGATION TOGGLE
.navbar-toggler.btn {
    display: none;
    @include media-breakpoint-between(xs, md) {
        border-color: $teal;
        color: $teal;
        display: inline-block;
        margin-left: 1.8rem;
        .navbar-toggler-icon {
            position: relative;
            display: inline-block;
            width: 1.5rem;
            height: 0.15rem;
            background: #000;
            transition: all .2s ease-in-out;
            &:before,
            &:after {
                background: #000;
                content: '';
                display: block;
                width: 1.5rem;
                height: 0.15rem;
                position: absolute;
                transition: all .2s ease-in-out;
            }
            &:before {
                top: -0.5rem;
            }
            &:after {
                top: 0.5rem;
            }
        }
        &[aria-expanded="true"]{
            .navbar-toggler-icon {
                background: transparent;
                &:before,
                &:after {
                    top: 0;
                    height: 0.20rem;
                }
                &:before {    
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }  
        }
    }

    &:hover {
        background-color: $teal;
        border-color: $teal;
        color: white;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 0.8rem;
    }
}

/* FOOTER NAVIGATION */
.menu-footer-menu-container {
    display: inline-block;
    .menu {
        padding: 0;
        margin: 0;
        list-style: none;

        .menu-item {
            display: inline-block;
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
        
        &:last-of-type {
            margin-left: 10px;
        }
    }
}

/* Secondary nav */
.navbar--secondary {
    li {
        position: relative;
    }

    &.navbar-nav {
        flex-direction: row;
    }

    @include media-breakpoint-down(sm) {
        &.navbar-nav {
            flex-direction: column;
        }
    }

    .menu-item-has-children {
        .menu-dropdown-toggle {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            cursor: pointer
        }
    }

    .dropdown-menu {
        &.open {
            display: block;
            position: absolute;
            font-size: 1.4rem;

            li {
                display: block;
                margin-left: 0;
                padding: 0.4rem 0;
                
                a:active {
                    background-color: #dcdcdc;
                    color: inherit;
                }
            }
        }
    }
}






